@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
kv
-------------------------------*/
.staff_kv {
  background: #fff url("../images/person/staff01/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
    background-position: -10em;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff01/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }

  .pages_kv_inner {
    @media #{$tab} {
      padding: 3em 0 0 1em;
    }
  }
}

.staff_kv02 {
  background: #fff url("../images/person/staff02/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff02/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }

  .staff_kv_txt {
    margin: 0 0 0 auto;
  }
}

.staff_kv03 {
  background: #fff url("../images/person/staff03/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff03/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }

  .staff_kv_txt {
    margin: 0 0 0 auto;
  }
}

.staff_kv04 {
  background: #fff url("../images/person/staff04/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff04/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv05 {
  background: #fff url("../images/person/staff05/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff05/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv06 {
  background: #fff url("../images/person/staff06/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff06/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv07 {
  background: #fff url("../images/person/staff07/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff07/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv08 {
  background: #fff url("../images/person/staff08/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff08/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv09 {
  background: #fff url("../images/person/staff09/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff09/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv10 {
  background: #fff url("../images/person/staff10/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;

  @media #{$tab} {
    height: 425px;
  }

  @media #{$sp} {
    background: #fff url("../images/person/staff10/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

/*-------------------------------
社員を知る 一覧
-------------------------------*/

.person_contents {
  .post_item_area {
    @media #{$sp} {
      flex-direction: row;
    }
  }

  .post_txt {
    padding: 0;
    border-radius: 0.5em;
    position: relative;
    margin-bottom: 2em;
    transition: color .3s ease, background .3s ease, transform .3s ease, opacity .3s ease, border .3s ease, padding .3s ease, left .3s ease, bottom .3s ease, box-shadow .3s ease;

    @media #{$sp} {
      width: 48%;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 8px;
      background: #ff8000;
      border-top-left-radius: .5em;
      z-index: 2;

      @media #{$sp} {
        width: 80px;
        height: 5px;
        border-top-left-radius: .2em;
      }
    }

    &:hover {
      opacity: 1;
      transform: translateY(-8px);
      box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, .09);
    }

    a:hover,
    a:hover img,
    .link:hover {
      opacity: 0.9;
    }

    .post_txt_area {
      padding: 0.5em 1em 1em;
    }

    .ttl {
      font-size: 1.3rem;
      color: #000;
      position: relative;
      z-index: 1;
      margin-bottom: 0.5em;
    }

    .ttl_img {
      display: block;
      margin: -0.7em 0 0 2em;
      position: relative;
      z-index: -1;

      @media #{$sp} {
        margin: -1.2em 0 0 2em;
      }
    }

    .txt {
      line-height: 1.3em;
    }
  }
}


/*-------------------------------
社員を知る 詳細
-------------------------------*/

.staff_kv {
  .staff_kv_txt {
    background: rgba(255, 255, 255, 0.8);
    max-width: 610px;
    padding: 1.5em;
    margin: 0 auto 0 0.5em;

    @media #{$tab} {
      width: 50%;
      padding: 1em;
      margin: 0 auto 0 1em;
    }

    @media #{$sp} {
      width: 100%;
      padding: 0.5em 1em;
      position: absolute;
      left: 0;
      bottom: -10px;
      background: #fff;
      margin: 0;
    }

    .pages_kv_inner {
      padding-top: 15em;
    }
  }

  .staff_kv_txt02 {
    background: rgba(255, 255, 255, 0.8);
    max-width: 610px;
    padding: 1.5em;
    margin: 0 0.5em 0 auto;

    @media #{$tab} {
      width: 50%;
      padding: 1em;
      margin: 0 1em 0 auto;
    }

    @media #{$sp} {
      width: 100%;
      padding: 0.5em 1em;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #fff;
    }
  }

  .staff_kv_txt03 {
    background: rgba(255, 255, 255, 0.8);
    max-width: 500px;
    padding: 1.5em;
    margin: 0 0.5em 0 auto;

    @media #{$tab} {
      width: 45%;
      padding: 1em;
      margin: 0 1em 0 auto;
    }

    @media #{$sp} {
      width: 100%;
      padding: 0.5em 1em;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #fff;
    }
  }

  .pages_kv_inner {
    max-width: 1150px;
    margin: 0 auto;
  }

  .pages_kv_area {
    display: flex;

    @media #{$tab} {
      display: block;
    }

    @media #{$sp} {
      display: block;
    }
  }

  .pages_kv_ttl {
    font-size: 1.6rem;
    line-height: 1.2em;
    margin-right: 1.5em;
    margin-bottom: 0;

    @media #{$tab} {
      font-size: 1.4rem;
      margin-right: 0.5em;
      display: flex;
    }

    @media #{$sp} {
      font-size: 1.3rem;
      margin-right: 0;
      line-height: 1em;
    }

    .pages_kv_ttl_img {
      display: block;
      margin-top: -0.3em;
      margin-left: 1.5em;
      position: relative;
      z-index: -1;

      @media #{$tab} {
        width: 40%;
        vertical-align: middle;
        margin-top: 0;
        margin-left: 0.5em;
      }

      @media #{$sp} {
        display: inline-block;
        width: 30%;
        vertical-align: text-bottom;
        margin-left: 0.3em;
      }
    }
  }

  .pages_kv_txt {
    border-bottom: 2px solid $clr-org;
    padding-bottom: 1em;
    margin-bottom: 1em;

    @media #{$tab} {
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }

    @media #{$sp} {
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      color: $clr-org;
      font-family: $font_zenmaru;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.3em;
    }
  }

  .pages_kv_cat {
    line-height: 1.5em;
    font-weight: bold;

    @media #{$sp} {
      font-size: 0.8rem;
    }
  }
}

.sf_contents {
  .pages_sec_ttl03 {
    @media #{$tab} {
      width: 50%;
    }

    @media #{$sp} {
      width: 75%;
    }
  }

  .pages_sec_ttl04 {
    @media #{$tab} {
      width: 55%;
    }
  }

  .pages_sec_ttl05 {
    @media #{$tab} {
      width: 90%;
    }
  }

  .pages_item {
    padding: 3em 0 1em 0;

    @media #{$tab} {
      padding: 2em 0 1em 0;
    }

    @media #{$sp} {
      padding: 2em 0 1em 0;
    }
  }

  .msg_item {
    margin-bottom: 4em;

    @media #{$tab} {
      flex-direction: column-reverse;
    }

    @media #{$sp} {
      flex-direction: column-reverse;
      margin-bottom: 2em;
    }
  }

  .msg_txt_area {
    @media #{$tab} {
      width: 100%;
    }
  }

  .msg_img {
    @media #{$tab} {
      width: 60%;
      padding: 1em 0;
    }
  }

  .msg_img02 {
    @media #{$tab} {
      width: 100%;
      margin: 0 auto 1em auto;
    }

    @media #{$sp} {
      width: 100%;
      margin: 0 auto 1em auto;
    }
  }

  .staff_ttl {
    color: #ff8000;
    font-family: $font_zenmaru;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.3em;
    margin-bottom: .3em;
  }

  .sf_item {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 4em auto;
    background: #fff;
    padding: 3em;
    border-radius: 10px;
    position: relative;

    @media #{$sp} {
      padding: 1.5em;
    }
  }

  .msg_img {
    @media #{$sp} {
      width: 80%;
      margin: 0;
      padding: 1em 0;
    }
  }

  .more_btn {
    width: 270px;
    height: 55px;
    line-height: 50px;
    font-size: 1.1rem;
    border: 2px solid #ff8000;
  }

  .sf_item_txt_area {
    display: flex;
    justify-content: space-between;

    @media #{$sp} {
      flex-direction: column;
    }
  }

  .sf_item_txt {
    width: 95%;

    @media #{$sp} {
      width: 100%;
    }
  }

  .icon {
    /*width: 15%;*/
    margin-left: 1.5em;

    @media #{$sp} {
      width: 25%;
      margin: 0 0 0 auto;
    }
  }
}