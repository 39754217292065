@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
動画で見る KV
-------------------------------*/
.movie_kv {
  background: #fff url("../images/movie/kv_img.jpg") right no-repeat;

  @media #{$tab} {
    background-position: -20em;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/movie/kv_img_sp.jpg") right no-repeat;
    background-size: contain;
    background-position: bottom;
  }

  .kv_ttl_img {
    display: block;
    width: 20%;
    margin: 0 0 0 10em;
  }
}

/*-------------------------------
学研版地域包括ケアシステム
-------------------------------*/
.movie_contents {
  .pages_sec_ttl03 {
    @include f_mplus;
    border-bottom: 2px solid $clr-org;
    margin-bottom: 35px;
    padding-bottom: 25px;
    font-weight: 700;
    @include fz(30);
    @media #{$sp} {
      @include fz(24);
      margin-bottom: 25px;
    }
  }
  .movieGuide{
    margin-bottom: 3em;
    @media #{$sp} {
      margin-bottom: 2em;
    }
    &_list{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media #{$sp} {
        justify-content: flex-start;
      }
      &Item{
        position: relative;
        display: inline-block;
        text-align: center;
        padding-bottom: 25px;
        margin-bottom: 30px;
        @media #{$pc} {//1024-
          width: calc(308 / 1000 * 100%);
          &:not(:nth-child(3n)){
            margin-right: calc(38 / 1000 * 100%);
          }
        }
        @media #{$tab} {//641-1024
          width: calc(308 / 1000 * 100%);
          &:not(:nth-child(3n)){
            margin-right: calc(38 / 1000 * 100%);
          }
        }
        @media #{$sp} {
          width: calc((100% - 4%) / 2);
          padding-bottom: 20px;
          margin-bottom: 15px;
          &:not(:nth-child(2n)){
            margin-right: 4%;
          }
        }
        &:link,&:visited{
          color: #000;
        }
        &:before{
          position: absolute;
          content: "";
          width: 1.5em;
          height: 1em;
          bottom: 0;
          left: 50%;
          transform: translate(-50%,0);
          background: url("../images/movie/arrow.svg") no-repeat center;
          background-size: contain;
        }
        figure{
          padding-bottom: 15px;
        }
        p{
          @include f_mplus;
          font-weight: 700;
          color: #000;
          @include fz(20);
          @media #{$sp} {
            @include fz(13);
          }
        }
      }
    }
  }

  .anchor{
    padding-top: 80px;
    margin-top: -80px;
  }
  .pages_item {
    &:last-of-type{
      margin-bottom: 0;
    }
    .pages_item_inner {
      @media #{$sp} {
        width: 90%;
      }
    }
    @media #{$pc} {//1024-

    }
    @media #{$tab} {//641-1024

    }
    @media #{$sp} {
      margin-bottom: 3em;
    }
    .movieCont{
      display: flex;
      align-items: center;
      @media #{$sp} {
        display: block;
      }
      &:not(:last-of-type){
        margin-bottom: 60px;
        @media #{$sp} {
          margin-bottom: 2em;
        }
      }
      &_img{
        width: calc(450 / 910 * 100%);
        @media #{$sp} {
          width: 100%;
          margin-bottom: 1em;
        }
        a{
          position: relative;
          display: inline-block;
          &:after{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url("../images/movie/play.svg") no-repeat center;
            z-index: 0;
          }
          &:before{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(#000,.2);
            z-index: 0;
            transition: background-color .3s;
          }
          &:hover{
            opacity: 1;
            *{
              opacity: 1;
            }
            &:after{
              opacity: 1;
              animation-name: videoplay;
              animation-duration: .3s;
              animation-timing-function: ease;
              animation-iteration-count: 1;
              animation-fill-mode:forwards;
            }
            &:before{
              opacity: 1;
              background: rgba(#000,.0);
            }
          }
        }
        
      }
      &_txt{
        width: calc(430 / 910 * 100%);
        margin-left: calc(30 / 910 * 100%);
        @media #{$sp} {
          width: 100%;
          margin-left: 0;
        }
      }
      &_ttl{
        @include f_mplus;
        margin-bottom: 10px;
        font-weight: 700;
        $lh:32/30;
        line-height: $lh;
        @include fz(30);
        @media #{$sp} {
          @include fz(22);
        }
        >span{
          display: inline-block;
          $lh:37/30;
          line-height: $lh;
          @include fz(20);
          @media #{$sp} {
            @include fz(15);
          }
        }
      }
    }
  }
}

#modalMovie{
  .videoPlayer_wrap{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .modal__content{
    @media #{$sp} {
      background-color: transparent;
      padding: 0;
      width: 100%;
    }
  }
  .close{
    @media #{$sp} {
      display: none;
    }
  }
}


@keyframes videoplay{
  0%{
    transform: scale(1,1);
  }
  50%{
    transform: scale(1.2,1.2);
  }
  80%{
    transform: scale(.9,.9);
  }
  100%{
    transform: scale(1,1);
  }
}

