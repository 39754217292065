@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
わたしたちの想い hope KV
-------------------------------*/
.hope_kv {
  background: #fff url("../images/hope/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -20em;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/hope/kv_img_sp.png") 1em 4em no-repeat;
    background-size: contain;
  }

  .kv_ttl_img {
    display: block;
    width: 35%;
    margin: 0 0 0 5em;
  }
}

/*-------------------------------
わたしたちの想い hope
-------------------------------*/
.hope_contents {
  .hope_item {
    position: relative;

    &:before {
      content: "";
      display: block;
      background: $clr-org;
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;

      @media #{$tab_s} {
        left: 10%;
      }
    }

    &_col {
      width: 390px;
      padding: 20px;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: .5em;
      margin-bottom: 30px;
      position: relative;

      @media #{$tab} {
        padding: 1em 2em;
      }

      @media #{$tab_s} {
        width: 70%;
        margin: 0 0 2em auto;
      }

      @media #{$sp} {
        padding: 1em;
      }

      &:before {
        content: "";
        position: absolute;
        top: 9%;
        border: 10px solid transparent;

        @media #{$sp} {
          top: 8%;
        }
      }

      &:nth-child(odd) {
        float: left;

        @media #{$tab_s} {
          float: none;
        }

        &:before {
          border-left: 15px solid #fff;
          left: 100%;

          @media #{$tab} {
            transform: rotateY(180deg);
            left: -5%;
          }

          @media #{$sp} {
            transform: rotateY(180deg);
            left: -9%;
          }
        }

        .year {
          right: -50%;
          margin-right: 5px;

          @media #{$tab_s} {
            right: 0;
            left: -43%;
          }
        }
      }

      &:nth-child(even) {
        float: right;

        @media #{$tab_s} {
          float: none;
        }

        &:before {
          border-right: 15px solid #fff;
          right: 100%;
        }

        .year {
          left: -50%;
          margin-left: 10px;

          @media #{$tab_s} {
            left: -43%;
            margin-left: 0;
          }
        }
      }

      &:nth-of-type(1) {
        &:before {
          top: 5%;
        }

        .year {
          top: 0;
        }
      }

      &:nth-of-type(2) {
        margin-top: 240px;

        @media #{$tab_s} {
          margin-top: 0;
        }

        &:after {
          content: "";
          display: block;
          background: url("../images/hope/lr_img01.png")center/contain no-repeat;
          width: 100%;
          height: 230px;
          position: absolute;
          top: -75%;
          left: -10%;

          @media #{$tab} {
            height: 170px;
            background-position: left;
            top: -55%;
            left: -35%;
          }

          @media #{$sp} {
            height: 100px;
            background-position: left;
            top: -55%;
            left: -40%;
          }
        }
      }

      &:nth-of-type(7) {
        &:before {
          top: 20%;
        }

        .hope_item_ttl {
          @include fz(24);

          @media #{$sp} {
            @include fz(20);
          }
        }
      }

      figure {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    &:nth-of-type(1) {
      .hope_item_col {
        &:nth-of-type(1) {
          &:after {
            content: "";
            display: block;
            background: url("../images/hope/icon.png")center/contain no-repeat;
            width: 106px;
            height: 26px;
            position: absolute;
            top: 35%;
            right: -43%;

            @media #{$tab_s} {
              right: 0;
              left: -39%;
            }

            @media #{$sp} {
              width: 65px;
            }
          }
        }

        &:nth-of-type(3) {
          &:after {
            content: "";
            display: block;
            background: url("../images/hope/icon.png")center/contain no-repeat;
            width: 106px;
            height: 26px;
            position: absolute;
            top: 50%;
            right: -43%;

            @media #{$tab_s} {
              right: 0;
              left: -39%;
            }

            @media #{$sp} {
              width: 65px;
            }
          }
        }

        &:nth-of-type(4) {
          margin-top: 180px;

          @media #{$tab_s} {
            margin-top: 8em;
          }

          &:after {
            content: "";
            display: block;
            background: url("../images/hope/lr_img02.png")center/contain no-repeat;
            width: 100%;
            height: 130px;
            position: absolute;
            top: -35%;
            left: 0;

            @media #{$tab_s} {
              height: 100px;
              top: -29%;
            }
          }
        }

        &:nth-of-type(5) {
          margin-top: 230px;

          @media #{$tab_s} {
            margin-top: 8em;
          }

          &:after {
            content: "";
            display: block;
            background: url("../images/hope/lr_img03.png")center/contain no-repeat;
            width: 100%;
            height: 115px;
            position: absolute;
            top: -30%;
            left: 0;

            @media #{$tab_s} {
              height: 100px;
              top: -25%;
            }
          }
        }
      }
    }

    &:nth-of-type(2) {
      .hope_item_col {
        &:nth-of-type(1) {
          &:after {
            content: "";
            display: block;
            background: url("../images/hope/lr_img04.png")center/contain no-repeat;
            width: 100%;
            height: 118px;
            position: absolute;
            top: -25%;
            left: -60%;

            @media #{$tab} {
              height: 100px;
              top: 20%;
            }

            @media #{$sp} {
              height: 60px;
              top: 20%;
            }
          }
        }

        &:nth-of-type(2) {
          &:before {
            top: 49%;
          }

          &:after {
            background: url("../images/hope/lr_img05.png")center/contain no-repeat;
            height: 168px;
            top: -45%;

            @media #{$tab} {
              height: 130px;
              background-position: left;
              top: 0;
              left: -28%;
            }

            @media #{$sp} {
              height: 70px;
              background-position: left;
              top: 0;
              left: -45%;
            }
          }
        }
      }
    }

    &:nth-of-type(3) {
      .hope_item_col {
        &:nth-of-type(2) {
          &:after {
            background: none;
          }
        }

        &:nth-of-type(3) {
          &:after {
            content: "";
            display: block;
            background: url("../images/hope/icon.png")center/contain no-repeat;
            width: 106px;
            height: 26px;
            position: absolute;
            bottom: 10%;
            right: -43%;

            @media #{$tab_s} {
              right: 0;
              left: -39%;
            }

            @media #{$sp} {
              width: 65px;
            }
          }
        }
      }
    }

    &_ttl {
      font-family: $font_zenmaru;
      color: $clr-org;
      @include fz(26);
      font-weight: bold;
      font-feature-settings: "palt";
      line-height: 1.3;
      margin-bottom: 15px;

      @media #{$sp} {
        @include fz(21);
        margin-bottom: .3em;
        font-weight: bold;
      }
    }

    p {
      line-height: 1.5;
    }

    .year {
      display: block;
      width: 155px;
      padding: 10px 0;
      text-align: center;
      border: 2px solid $clr-org;
      border-radius: .5em;
      background: #fff;
      position: absolute;
      top: 5%;
      z-index: 2;

      @media #{$sp} {
        width: 35%;

        img {
          width: 75%;
        }
      }
    }

    .year_btm {
      top: auto;
      bottom: 0;
      right: 42%;

      @media #{$tab} {
        right: 0;
        left: 0;
      }

      @media #{$sp} {
        width: 24.5%;
        right: 0;
        left: -5px;

        img {
          width: 90%;
        }
      }
    }

    &_btm {
      text-align: center;
      padding: 65px 0;
      margin-top: 60px;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: .5em;
      position: relative;

      @media #{$tab_s} {
        padding: 2em;
      }

      &:before {
        content: "";
        position: absolute;
        top: -5%;
        left: 47.5%;
        border: 25px solid transparent;
        border-bottom: 35px solid #fff;

        @media #{$sp} {
          top: -7%;
          left: 43%;
        }
      }

      h4 {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 50px;
      }

      a {
        color: $clr_org;

        @media #{$sp} {
          display: block;
        }

        span {
          text-decoration: underline;
        }
      }
    }
  }
}