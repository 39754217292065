@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
キャリアパス／人事制度 careerpass KV
-------------------------------*/
.career_kv {
  background: #fff url("../images/careerpass/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -50em;
  }

  @media #{$sp} {
    background: #fff url("../images/careerpass/kv_img_sp.png") right 4em no-repeat;
  }

  .kv_ttl_img {
    display: block;
    width: 55%;
    margin: 0 0 0 0.5em;
  }
}

/*-------------------------------
キャリアパス／人事制度 careerpass
-------------------------------*/

.career_contents {
  .pages_sec_ttl {
    width: 73%;
    border-bottom: 2px solid $clr-org;
    padding-bottom: 1em;
    position: relative;

    @media #{$tab} {
      margin-bottom: 1em;
    }

    @media #{$sp} {
      width: 100%;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }

    &:before {
      display: none;
    }

    &:after {
      background: url("../images/careerpass/ttl_img.png")right no-repeat;
      width: 100%;
      height: auto;
      padding-bottom: 23%;
      background-size: contain;
      position: absolute;
      top: -.5em;
      right: -13.5em;

      @media #{$tab} {
        top: -1em;
        right: -9em;
      }

      @media #{$sp} {
        top: -1.4em;
        right: -1em;
        padding-bottom: 20%;
      }
    }

    img {
      @media #{$sp} {
        width: 60%;
      }
    }
  }

  .pages_sec_ttl03 {
    border-bottom: 2px solid $clr-org;
    padding-bottom: 1em;
    margin-bottom: 1em;

    @media #{$sp} {
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }

    img {
      @media #{$sp} {
        width: 70%;
      }
    }
  }

  .pages_sec_ttl02 {
    font-weight: bold;
    margin-bottom: 0.5em;

    @media #{$sp} {
      font-size: 1rem;
    }
  }

  .post_txt_t {
    @media #{$sp} {
      margin-bottom: 1em;
    }
  }

  .sec01_item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &_txt {
      padding: 15px 0 0 15px;
      color: $clr-org;
    }

    &_btm {
      max-width: 800px;
      margin: 0 auto;

      .sec_fx {
        justify-content: space-between;

        @media #{$sp} {
          flex-wrap: wrap;
        }
      }
    }
  }

  .sec_col_item {
    width: 40%;

    &_top {
      margin-bottom: 85px;

      .sec_col_item {
        position: relative;

        &:nth-of-type(1) {
          &:after {
            content: "";
            display: inline-block;
            background: url(../images/careerpass/arrow_n.png) center / contain no-repeat;
            width: 59px;
            height: 72px;
            position: absolute;
            top: 50%;
            right: -35%;

            @media #{$sp} {
              width: 40px;
              height: 50px;
              right: -37%;
            }
          }
        }

        &:nth-of-type(2) {
          margin-top: 70px;

          &:after {
            content: "";
            display: inline-block;
            background: url(../images/careerpass/arrow_b.png) center / contain no-repeat;
            width: 85px;
            height: 55px;
            position: absolute;
            bottom: -27%;
            right: 35%;

            @media #{$sp} {
              width: 55px;
              bottom: -35%;
              right: 30%;
            }
          }
        }
      }
    }

    &_ttl {
      text-align: center;
      padding: .7em 0;
      border-top-left-radius: .5em;
      border-top-right-radius: .5em;
      background: $clr-org;

      @media #{$sp} {
        padding: .5em;
      }
    }

    &_inner {
      padding: 30px;
      text-align: center;
      border-bottom-left-radius: .5em;
      border-bottom-right-radius: .5em;
      border: 1px solid $clr-org;
      background: $clr-yel;

      @media #{$sp} {
        padding: .5em;
      }
    }

    .txt {
      text-align: left;
    }

    .modal {
      .modal__ttl {
        @media #{$sp} {
          padding-left: 0;
        }

        &_area {
          width: 100%;
        }

        &_img {
          margin-right: 15px;

          @media #{$sp} {
            margin-right: .2em;

            img {
              width: 80% !important;
            }
          }
        }

        img {
          @media #{$sp} {
            width: 65%;
          }
        }
      }
    }

    &_md {
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        background: url(../images/careerpass/arrow_s.png) center / contain no-repeat;
        width: 74px;
        height: 69px;
        position: absolute;
        bottom: -20%;
        right: 45%;

        @media #{$sp} {
          width: 55px;
          height: 50px;
          bottom: -10.5%;
          right: 42%;
        }
      }

      &_ttl {
        padding: .5em 0;
        text-align: center;
        margin-bottom: 25px;
        border-radius: 2em;
        background: $clr-org;
      }

      &_cat {
        padding: 1em 0;
        text-align: center;
        background: $clr-red;
        border-top-right-radius: .5em;
        border-top-left-radius: .5em;

        @media #{$sp} {
          padding: .5em 0;

          img {
            width: 45%;
          }
        }
      }

      .sec_col_item {
        width: 100%;
        margin: 35px auto 95px auto;

        @media #{$sp} {
          margin: 2em auto 4em auto;
        }

        &_inner {
          background: $clr-pnk;
          border: 1px solid $clr-red;
          padding-bottom: 0;
        }

        &_link {
          @media #{$sp} {
            width: 45%;
            padding-top: 1em;
          }
        }

        .pop_img {
          @media #{$sp} {
            width: 52%;
            padding-top: 1.1em;
          }
        }
      }

      &_sc {
        &:after {
          background: url(../images/careerpass/arrow_b.png) center / contain no-repeat;
          width: 85px;
          height: 55px;
          bottom: -15%;

          @media #{$tab} {
            bottom: -12%;
          }

          @media #{$sp} {
            bottom: -8%;
          }
        }

        .sec_col_item {
          &_md_cat {
            background: $clr-bgrn;
          }

          &_inner {
            flex-wrap: wrap;
            background: $clr-sgrn;
            border: 1px solid $clr-bgrn;
          }

          &_link {
            margin-bottom: 15px;
          }
        }
      }
    }

    &_bm {
      &_cat {
        text-align: center;
        padding: 1.5em;
        border-radius: .5em;
        background: $clr-sblu;
        border: 1px solid $clr-blu;

        @media #{$sp} {
          padding: 1em;

          img {
            width: 18%;
          }
        }
      }
    }
  }
}

.career_contents_sec02 {
  padding: 3em 0 1em 0;

  .pages_item_inner {
    @media #{$sp} {
      width: 90%;
    }
  }

  .pages_sec_ttl03 {
    img {
      @media #{$sp} {
        width: 40%;
      }
    }
  }
}

.career_contents_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  @media #{$sp} {
    flex-direction: column-reverse;
    align-items: center;
  }

  .career_item_txt {
    width: 74%;

    @media #{$tab} {
      width: 100%;
      margin-right: 2em;
    }

    @media #{$sp} {
      width: 100%;
    }
  }

  .txt {
    line-height: 1.8em;
  }

  .career_item_img {
    @media #{$sp} {
      width: 40%;
    }
  }
}

.career_img {
  max-width: 878px;
  margin: 0 auto;
  position: relative;

  z-index: 1;

  @media #{$tab} {
    width: 85%;
  }

  @media #{$sp} {
    width: 90%;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 65px;
    height: 65px;
    background: url("../images/careerpass/txt.png")left no-repeat;
    animation: swing .9s infinite;
    position: absolute;
    top: 5em;
    left: -2em;
    z-index: 2;

    @media #{$tab} {
      width: 60px;
      height: 60px;
      background-size: contain;
      top: 3em;
    }

    @media #{$sp} {
      width: 30px;
      height: 30px;
      background-size: contain;
      top: 1em;
      left: -0.5em;
    }
  }
}

.career_img_bk {
  position: absolute;
  top: 17%;
  left: 9%;
  z-index: -1;

  @media #{$tab} {
    width: 100%;
  }

  @media #{$sp} {
    width: 100%;
  }
}

.sec_career_area {
  margin-bottom: 60px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ff8000;

  @media #{$sp} {
    margin-bottom: 2em;
    padding-bottom: 2em;
  }

  &:nth-last-of-type(1) {
    border: none;
    padding-bottom: 0;
    margin-bottom: 25px;
  }
}

.sec_career {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media #{$tab} {
    margin-bottom: 40px;
  }

  @media #{$sp} {
    flex-direction: column;
    margin-bottom: 0;

    figure {
      width: 45%;
    }
  }

  &_lt {
    width: 70%;

    @media #{$tab} {
      width: 90%;
      margin-left: 1em;
    }

    @media #{$sp} {
      width: 100%;
    }
  }

  &_lt_item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #808080;
    padding-bottom: 5px;
    margin-bottom: 20px;

    @media #{$sp} {
      padding-bottom: .5em;
      margin-bottom: 1em;
    }
  }

  &_name {
    @include fz(20);
    font-weight: bold;
    margin-right: 20px;
    position: relative;
    z-index: 2;

    @media #{$sp} {
      margin-right: 1em;
    }

    &_s {
      display: block;
      margin: -15px auto 0 50px;
      z-index: -1;
    }

    &_s_btm {
      margin: -25px auto 0 70px;
    }
  }

  &_year {
    width: 138px;
    @include fz(16);
    color: #fff;
    text-align: center;
    background: $clr-org;
    border-radius: 3em;
  }

  &_txt_btm {
    display: inline-block;
    position: relative;
    padding: 15px;
    margin: 15px auto 30px auto;
    border-radius: .5em;
    background: $clr-yel;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 30%;
      left: -6%;
      border: 10px solid transparent;
      border-right: 35px solid $clr-yel;
      transform: rotate(25deg);

      @media #{$sp} {
        top: -10%;
        left: 55%;
        transform: rotate(40deg);
      }
    }
  }
}

.sec_career_btm {
  max-width: 580px;
  margin: 0 3.5em 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media #{$tab} {
    max-width: 100%;
    margin: 0 auto;
  }

  @media #{$sp} {
    max-width: 90%;
    margin: 0 auto 0 0;
  }

  &_item {
    width: 28%;
    height: 160px;
    padding: 15px 3px;
    margin-bottom: 30px;
    text-align: center;
    border-radius: .3em;
    border: 1px solid $clr-red;
    border-top: 7px solid $clr-red;
    background: $clr-pnk;
    position: relative;

    @media #{$tab} {
      height: auto;
    }

    @media #{$sp} {
      width: 45%;
    }

    &:nth-of-type(1) {
      &:before {
        content: "";
        display: inline-block;
        background: url("../images/careerpass/pop01_img02.png")center/contain no-repeat;
        width: 72px;
        height: 83px;
        position: absolute;
        bottom: -15%;
        right: -10%;
        z-index: 2;

        @media #{$sp} {
          height: 70px;
          right: -25%;
        }
      }
    }

    &_cat {
      margin-bottom: 25px;
    }

    &_cat_s {
      margin-bottom: 15px;
    }

    &_txt {
      font-family: $font_zenmaru;
      @include fz(17);
      font-weight: 600;
      color: $clr-red;
      line-height: 1.3;

      @media #{$sp} {
        @include fz(16);
      }

      .txt_b {
        display: block;
        @include fz(16);

        @media #{$sp} {
          @include fz(14);
        }
      }

      .txt_s {
        display: block;
        @include fz(15);

        @media #{$sp} {
          @include fz(14);
        }
      }
    }

    &_txt_s {
      width: 60%;
      padding: .2em 0;
      font-family: $font_zenmaru;
      ;
      @include fz(14);
      font-weight: 500;
      color: #fff;
      background: $clr-grn;
      border-radius: 1em;
      position: absolute;
      bottom: -9%;
      left: 20%;

      @media #{$sp} {
        width: 65%;
        @include fz(13);
      }
    }
  }

  .txt_red {
    background: $clr-red;
  }

  .txt_grn {
    color: $clr-grn;
  }

  .txt_arrow {
    position: relative;
    margin-bottom: 15px;

    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 9px 0 9px;
      border-color: $clr_org transparent transparent transparent;
      position: absolute;
      left: 43%;
      bottom: -40%;
    }
  }

  .arrow {
    position: relative;

    &:after {
      content: "";
      display: block;
      background: url("../images/careerpass/sec02_arrow.png")center/contain no-repeat;
      width: 45px;
      height: 62px;
      position: absolute;
      top: 30%;
      right: -46px;

      @media #{$sp} {
        width: 30px;
        right: -31px;
      }
    }
  }

  .arrow_sp {
    position: relative;

    @media #{$sp} {
      &:after {
        content: "";
        display: block;
        background: url("../images/careerpass/sec02_arrow.png")center/contain no-repeat;
        width: 30px;
        height: 62px;
        position: absolute;
        top: 30%;
        right: -31px;
      }
    }
  }

  .arrow_b {
    position: relative;

    &:before {
      content: "";
      display: block;
      background: url("../images/careerpass/sec02_arrow_b.png")center/contain no-repeat;
      width: 75px;
      height: 62px;
      position: absolute;
      top: 30%;
      left: -83px;

      @media #{$tab_s} {
        display: none;
      }
    }
  }

  .item_grn {
    border: 1px solid $clr-grn;
    border-top: 7px solid $clr-grn;
    background: $clr-sgrn;
  }

  .icon_left {
    &:before {
      right: auto;
      left: -30%;

      @media #{$sp} {
        left: auto;
      }
    }
  }

  .icon_top {
    &:before {
      content: "";
      display: inline-block;
      background: url("../images/careerpass/pop05_img02.png")center/contain no-repeat;
      width: 81px;
      height: 80px;
      position: absolute;
      top: -45%;
      right: -30%;
      z-index: 2;

      @media #{$sp} {
        height: 60px;
        right: 80%;
      }
    }

    &_lt {
      &:before {
        right: auto;
        top: 15%;
        left: -30%;

        @media #{$sp} {
          top: 70%;
        }
      }
    }
  }
}