@charset "utf-8";
@import "../_variables";
@import "../_mixin";
/*-------------------------------
新着情報 news
-------------------------------*/

.news_contents {
  .post_item {
    width: 100%;

    .ttl {
      color: #000;
      font-size: 1rem;
      font-weight: 100;
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        height: 15px;
        border-right: 1px solid #000;
        margin: 0 0.5em;
        vertical-align: middle;

        @media #{$sp} {
          border-right: none;
        }
      }
    }
  }

  .post_txt_item_area {
    border-bottom: 1px solid #000;
    position: relative;

    &:nth-last-of-type(1) {
      border-bottom: none;
    }

    .icon_new {
      content: "";
      display: inline-block;
      background: $clr-org;
      color: #fff;
      text-align: center;
      width: 65px;
      margin-right: 1em;
      position: absolute;
      top: 50%;
      left: 10%;
      margin-top: -12px;

      @media #{$tab} {
        display: block;
        margin-top: 1em;
        position: static;
      }

      @media #{$sp} {
        display: block;
        margin-top: 1em;
        position: static;
      }
    }
  }

  .post_txt_item {
    max-width: 530px;
    margin: 0 auto;
    padding: 1em 0;

    @media #{$tab} {
      margin: 0 auto 0 0;
    }

    @media #{$sp} {
      display: block;
      margin: 0;
      padding: 0.5em 0 1em 0;
    }

    &:nth-last-of-type(1) {
      border-bottom: none;
    }

    .txt {
      display: inline-block;
      font-size: 1rem;
      font-weight: 100;
    }
  }

  .post_txt {
    padding: 3em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media #{$sp} {
      padding: 1em 1.5em;
    }
  }

  .ttl02 {
    margin-left: 5em;

    @media #{$sp} {
      margin-left: 0;
    }
  }

  .news_link {
    text-align: right;

    @media #{$sp} {
      text-align: center;
    }
  }

  .link_btn {
    display: inline-block;
    width: 290px;
    margin-bottom: 1em;
    padding: 0.5em 0 1em 0;
    border: 1px solid #2995CC;
    background: #2995CC;
    text-align: center;
    position: relative;

    @media #{$sp} {
      width: 95%;
      font-size: 1rem;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      border-right: 1.5px solid #fff;
      border-bottom: 1.5px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .link_btn02 {
    border: 1px solid #87C426;
    background: #87C426;
    margin-left: 0.8em;

    @media #{$sp} {
      margin-left: 0;
    }
  }
}