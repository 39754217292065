@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
社長メッセージ topmessage KV
-------------------------------*/
.msg_kv {
  background: #fff url("../images/topmessage/kv_img.png") no-repeat;
  background-size: cover;
  padding-bottom: 45%;

  @media #{$tab} {
    background-position: 0;
    padding-bottom: 50%;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/topmessage/kv_img.png") no-repeat;
    background-size: contain;
  }

  .kv_ttl_img {
    display: block;
    width: 65%;
    margin: 0.2em 0 0 1em;

    @media #{$tab} {
      width: 45%;
    }

    @media #{$sp} {
      display: inline-block;
      width: 25%;
      margin: 0.2em 0 0 0.5em;
    }
  }

  .msg_kv_txt {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 170px;

    @media #{$tab} {
      height: auto;
    }

    @media #{$sp} {
      height: auto;
    }
  }

  .msg_kv_ttl {
    display: block;
    font-size: 0.9rem;
    padding-bottom: 0.5em;

    @media #{$tab} {
      padding-bottom: 0;
    }

    @media #{$sp} {
      display: inline-block;
      font-size: 0.8rem;
      padding-bottom: 0;
      padding-right: 0.5em;
    }
  }

  .pages_kv_inner {
    display: flex;
    justify-content: center;
    padding-top: 3em;

    @media #{$tab} {
      align-items: center;
      padding: 1em 0 0 0;
    }

    @media #{$sp} {
      flex-direction: column;
      padding: 0.5em 0;
    }
  }

  .pages_kv_inner .pages_kv_ttl {
    @media #{$sp} {
      font-size: 1.3rem;
      margin-bottom: 0.5em;
    }
  }

  .pages_kv_inner .pages_kv_txt {
    @media #{$tab} {
      max-width: 55%;
    }

    @media #{$sp} {
      max-width: 85%;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.msg_kv_hd {
  background: #fff url("../images/topmessage/hd/kv_img.png") center no-repeat;

  @media #{$tab} {
    background-size: cover;
  }
}

/*-------------------------------
社長メッセージ topmessage
-------------------------------*/

.msg_contents {
  .pages_inner {
    @media #{$sp} {
      padding: 3em 0;
    }
  }

  .pages_sec_ttl03 {
    margin-bottom: 1em;

    @media #{$tab} {
      width: 80%;
    }

    @media #{$sp} {
      width: 60%;
      margin-bottom: 0.5em;
    }
  }

  .pages_sec_ttl04 {
    margin-bottom: 1em;

    @media #{$sp} {
      width: 90%;
    }
  }

  .pages_sec_ttl05 {
    margin-bottom: 1em;

    @media #{$tab} {
      width: 75%;
    }

    @media #{$sp} {
      width: 70%;
    }
  }
}

.msg_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7em;
  padding: 0 3em;

  @media #{$sp} {
    flex-direction: column;
    padding: 0 1.5em;
    margin-bottom: 3em;
  }
}

.msg_item02 {
  margin-bottom: 7em;
  padding: 0 3em;

  @media #{$sp} {
    padding: 0 1.5em;
    margin-bottom: 3em;
  }
}

.msg_item03 {
  margin-bottom: 3em;
  padding: 0 3em;

  @media #{$sp} {
    padding: 0 1.5em;
    margin-bottom: 2em;
  }
}

.msg_item_sec {
  @media #{$tab_s} {
    flex-direction: column-reverse !important;
  }
}

.msg_item_bk {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 50%;
    height: 4px;
    background: $clr-org;
    border-radius: 1px;
    position: absolute;
    bottom: -3em;
    right: 0;

    @media #{$sp} {
      bottom: -1.5em;
      height: 2px;
    }
  }
}

.msg_item_bk02 {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 50%;
    height: 4px;
    background: $clr-org;
    border-radius: 1px;
    position: absolute;
    bottom: -3em;
    left: 0;

    @media #{$sp} {
      bottom: -1.5em;
      height: 2px;
    }
  }
}

.msg_txt_area {
  width: 47%;

  @media #{$sp} {
    width: 100%;
  }
}

.msg_img {
  width: 50%;

  @media #{$tab} {
    width: 45%;
    padding-top: 1em;
  }

  @media #{$sp} {
    width: 90%;
    margin: 0 auto;
    padding-top: 1em;
  }
}

.msg_txt_area02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;

  @media #{$sp} {
    flex-direction: column;
  }

  .txt {
    width: 48%;
    text-align: justify;

    @media #{$sp} {
      width: 100%;
      /*display: contents;*/
    }
  }
}

.msg_img_area {
  width: 41%;

  @media #{$sp} {
    width: 100%;
  }
}

.msg_img02 {
  margin-bottom: 2.5em;

  @media #{$sp} {
    width: 90%;
    margin-bottom: 1em;
  }
}

.msg_img03 {
  text-align: right;

  @media #{$sp} {
    width: 60%;
    margin: 0 0 2em auto;
  }
}

.msg_contents_02 {
  .msg_item {
    @media #{$tab} {
      flex-direction: column;

      .msg_txt_area {
        width: 100%;
      }
    }
  }

  .pages_sec_ttl03 {
    @media #{$sp} {
      width: 100%;
    }
  }

  .pages_sec_ttl04 {
    @media #{$tab} {
      width: 70%;
    }

    @media #{$sp} {
      width: 65%;
      margin-bottom: .5em;
    }
  }

  .pages_sec_ttl04_sp {
    @media #{$sp} {
      width: 100%;
    }
  }

  .pages_sec_ttl05 {
    @media #{$tab} {
      width: 55%;
    }

    @media #{$sp} {
      width: 80%;
      margin-bottom: 1em;
    }
  }
}