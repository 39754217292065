@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
学研版地域包括ケアシステム KV
-------------------------------*/
.care-system_kv {
  background: #fff url("../images/care-system/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -20em;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/care-system/kv_img_sp.png") right no-repeat;
    background-size: cover;
  }

  .kv_ttl_img {
    display: block;
    width: 35%;
    margin: 0 0 0 5em;
  }
}

/*-------------------------------
学研版地域包括ケアシステム
-------------------------------*/
.care-system_contents {
  .pages_sec_ttl03 {
    border-bottom: 2px solid $clr-org;
    margin-bottom: 25px;
    padding-bottom: 20px;
  }

  .pages_item {
    &_txt {
      margin-bottom: 15px;
    }

    &_md {
      margin-bottom: 45px;

      .pages_item_txt {
        margin-bottom: 40px;
      }
    }

    &_btm {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 60px 0;

      @media #{$tab_s} {
        margin: 0 0 2em 0;
      }

      @media #{$sp} {
        justify-content: space-between;
      }

      img {
        display: block;
        margin-right: 20px;

        &:nth-last-of-type(1) {
          margin: 0 0 0 20px;

          @media #{$tab_s} {
            margin: 0;
          }
        }

        @media #{$tab} {
          width: 29%;
          margin-bottom: 1em;
        }

        @media #{$sp} {
          width: 46%;
          margin: 0 0 1em 0;
        }
      }
    }

    &_link {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:after {
        content: "";
        display: block;
        width: 32%;
      }

      a {
        display: block;
        width: 23%;
        padding: 10px;
        margin-top: 15px;
        color: #fff;
        line-height: 1.5;
        background: $clr-grn;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$tab} {
          width: 32%;
        }

        @media #{$sp} {
          width: 48%;
        }

        &:after {
          content: "";
          display: inline-block;
          background: url("../images/care-system/icon.png")no-repeat;
          width: 11px;
          height: 11px;
          position: absolute;
          right: 5%;
          bottom: 5%;
        }
      }
    }
  }
}