@charset "utf-8";
@import "../_variables";
@import "../_mixin";
/*-------------------------------
学校関係者様へ school
-------------------------------*/

.school_contents {
  .pages_item {
    &:before {
      display: none;
    }
  }

  .pages_inner {
    @media #{$sp} {
      padding: 4em 0 2em 0;
    }
  }

  .txt {
    margin-bottom: 2em;

    @media #{$sp} {
      margin-bottom: 1em;
    }
  }

  .pages_item_ttl {
    color: $clr-org;
    margin-bottom: 0.5em;
    font-weight: bold;

    &:before {
      content: '';
      display: inline-block;
      position: relative;
      top: 2px;
      left: -5px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: $clr-org;
    }
  }

  .pages_txt_area {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding-top: 2em;
    margin-bottom: 2em;

    @media #{$sp} {
      margin-bottom: 1em;
    }
  }
}

.pages_info {
  .pages_txt_item {
    margin-bottom: 2em;

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }

    @media #{$sp} {
      margin-bottom: 1em;
    }
  }

  .pages_item_ttl02 {
    color: #000;
    margin-bottom: 0.5em;
    font-weight: bold;

    &:before {
      content: '';
      display: inline-block;
      position: relative;
      top: 2px;
      left: -5px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #000;
    }
  }

  .icon {
    background: url("../images/school/icon01.svg")no-repeat;
    width: 18px;
    height: 18px;
    background-size: contain;
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.3em;

    &:nth-of-type(2) {
      background: url("../images/school/icon02.svg")no-repeat;
    }

    &:nth-of-type(3) {
      background: url("../images/school/icon03.svg")no-repeat;
    }
  }

  .txt {
    text-align: left;
  }

  .txt a {
    @media #{$sp} {
      line-height: 2.5em;
    }
  }
}