@charset "utf-8";
@import "../_variables";
@import "../_mixin";
/*-------------------------------
会社概要 Company
-------------------------------*/

.company_contents {
  .pages_list {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0;

    tr {
      border-bottom: 1px solid #000;

      &:nth-last-of-type(1) {
        border-bottom: none;
      }

      @media #{$sp} {
        display: block;
        width: 95%;
        margin: 0 auto;
      }
    }

    th,
    td {
      font-weight: 400;
      line-height: 1.8em;
      vertical-align: middle;
      border: none;

      @media #{$sp} {
        font-size: 14px;
        border-top: none;
      }
    }

    th {
      width: 20%;
      height: 60px;
      text-align: left;
      background: none;
      font-weight: bold;
      color: #000;
      padding: 1em 1em 1em 3em;

      @media #{$tab} {
        width: 25%;
        padding: 1em;
      }

      @media #{$sp} {
        display: block;
        width: 100%;
        height: auto;
        padding: 0.5em 0 0 0;
      }
    }

    td {
      width: 85%;
      height: 60px;
      text-align: left;
      background: none;
      padding: 1em 2em;

      @media #{$sp} {
        width: 30%;
        height: auto;
        padding: 0 0 0.5em 0;
      }
    }
  }
}