@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
採用情報 recruitment
-------------------------------*/
.recruitment_kv {
  background: #fff url("../images/recruitment/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -35em;
  }

  @media #{$sp} {
    background: #fff url("../images/recruitment/kv_img_sp.png") right 4.5em no-repeat;
  }
}

.pages_contents {
  .pages_sec_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 1em;
    position: relative;

    &:before,
    &:after {
      content: "";
      flex-grow: 1;
      height: 2px;
      background: $clr-org;
      display: block;
    }

    &:before {
      margin-right: .4em;
    }

    &:after {
      margin-left: .4em;
    }

    img {
      @media #{$sp} {
        width: 30%;
      }
    }
  }

  .pages_sec_ttl01 {
    img {
      @media #{$sp} {
        width: 50%;
      }
    }
  }

  @media all and (-ms-high-contrast: none) {
    .pages_sec_ttl {
      align-items: flex-start;
    }
  }

  .pages_list {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5em;

    @media #{$sp} {
      margin-bottom: 3em;
    }

    tr {
      @media #{$sp} {
        display: block;
        width: 100%;
      }
    }

    th,
    td {
      font-weight: 400;
      line-height: 1.8em;
      vertical-align: middle;
      border: 1px solid #bfbfbf;

      @media #{$sp} {
        border-top: none;
      }
    }

    th {
      width: 20%;
      height: 60px;
      text-align: center;
      background: $clr-org;
      color: #fff;
      padding: 1em;

      @media #{$tab} {
        width: 25%;
        line-height: 1.5em;
        padding: 1em;
      }

      @media #{$sp} {
        display: block;
        width: 100%;
        font-size: 1rem;
        padding: 1em 1.5em;
      }
    }

    td {
      width: 85%;
      height: 60px;
      text-align: left;
      background: #ffffff;
      padding: 1em 2em;

      @media #{$tab} {
        padding: 0.5em 1em;
        line-height: 1.5em;
      }

      @media #{$sp} {
        width: 30%;
        padding: 1em 1.5em;
        font-size: 0.9rem;
        line-height: 1.5em;
      }
    }

    .note {
      font-size: 0.9rem;

      a {
        border-bottom: 1px solid #000;
      }
    }

    .list_item {
      margin-left: 1.5em;

      li {
        &::before {
          content: '';
          display: inline-block;
          position: relative;
          top: -2px;
          left: -5px;
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background: #000;
        }
      }
    }

    .des {
      padding: 0.8em 0;
    }
  }

  .pages_sec_ttl02 {
    color: $clr-org;
    margin-bottom: 1.5em;

    &:before {
      content: '';
      display: inline-block;
      position: relative;
      top: 2px;
      left: -5px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: $clr-org;
    }
  }

  .pages_img {
    margin-bottom: 5em;

    @media #{$sp} {
      margin-bottom: 3em;
    }
  }

  .btm_txt_area {
    margin-bottom: 5em;

    @media #{$sp} {
      margin-bottom: 3em;
    }
  }

  .btm_txt {
    margin-bottom: 1em;
  }

  .btm_txt02 {
    display: block;
    width: 485px;
    margin-bottom: 1em;
    background: #fff;
    color: $clr-grn;
    font-weight: bold;
    padding: 0.5em 1em;
    border: 2px solid $clr-grn;

    @media #{$sp} {
      width: 85%;
      margin: 0 auto 1em auto;
      font-size: 0.8rem;
      text-align: center;
      padding: .5em 0;
    }

    &:before {
      content: "";
      display: inline-block;
      background: url("../images/recruitment/dl_icon02.svg")no-repeat;
      width: 25px;
      height: 25px;
      background-size: contain;
      vertical-align: bottom;
      margin-right: 0.5em;

      @media #{$ie} {
        width: 35px;
        height: 30px;
      }

      @media #{$sp} {
        width: 20px;
        height: 20px;
      }

      @supports (-ms-ime-align: auto) {
        width: 40px;
        height: 35px;
        vertical-align: middle;
      }
    }

    &:hover {
      background: $clr-grn;
      color: #fff;
      opacity: 1;

      &:before {
        content: "";
        display: inline-block;
        background: url("../images/recruitment/dl_icon.svg")no-repeat;
        width: 25px;
        height: 25px;
        background-size: contain;
        vertical-align: bottom;
        margin-right: 0.5em;

        @media #{$ie} {
          width: 35px;
          height: 30px;
        }

        @media #{$sp} {
          width: 20px;
          height: 20px;
        }

        @supports (-ms-ime-align: auto) {
          width: 40px;
          height: 35px;
          vertical-align: middle;
        }
      }
    }
  }

  .btm_txt03 {
    color: #ff0000;
    border: 2px solid #ff0000;

    &:before {
      background: url("../images/recruitment/dl_icon03.svg")no-repeat;
    }

    &:hover {
      background: #ff0000;
    }
  }

  .pages_sec_ttl02 {
    margin-bottom: 1em;
  }

  .btn {
    display: block;
    width: 550px;
    margin: 0 auto;
    padding: 1.5em 0;
    background: $clr-grn;
    border: 1px solid $clr-grn;
    border-radius: 5em;
    text-align: center;
    position: relative;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    @media #{$tab} {
      width: 70%;
    }

    @media #{$sp} {
      width: 90%;
      padding: 1em 0 1.3em 0;

      img {
        width: 70%;
      }
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 2em;
      width: 15px;
      height: 15px;
      margin-top: -5px;
      border-right: 1.5px solid #fff;
      border-bottom: 1.5px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);

      @media #{$sp} {
        right: 1.5em;
        width: 10px;
        height: 10px;
      }
    }
  }

  .flow_list {
    max-width: 820px;
    margin: 0 auto 3em auto;

    &_item {
      height: 90px;
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #fff;
      box-shadow: 0 2px 2px rgba(204, 204, 204, .5);
      border-radius: .8em;
      position: relative;

      @media #{$sp} {
        height: 60px;
        margin-bottom: 2em;
      }

      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 50px 0 50px;
        border-color: $clr-org transparent transparent transparent;
        position: absolute;
        left: 50%;
        bottom: -60%;
        transform: translate(-50%, -50%);

        @media #{$sp} {
          border-width: 15px 30px 0 30px;
          bottom: -55%;
        }
      }

      &:nth-last-of-type(1) {
        margin-bottom: 0;

        &:after {
          display: none;
        }
      }

      p {
        color: $clr-org;
        font-weight: 600;
        text-align: center;
      }

      &_txt {
        font-size: 20px;
        line-height: 1.3em;

        @media #{$sp} {
          font-size: 17px;
        }
      }

      &_note {
        font-size: 20px;

        @media #{$sp} {
          font-size: 15px;
        }
      }
    }
  }
}