@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
会社説明会 infosession KV
-------------------------------*/
.infosession_kv {
  background: #fff url("../images/infosession/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -40em;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/infosession/kv_img_sp.png") -6em 4em no-repeat;
  }

  .infosession_kv_ttl_img {
    display: block;
    width: 35%;
    margin: 0.2em 0 0 1em;
  }
}

/*-------------------------------
会社説明会 infosession
-------------------------------*/

.infosession_contents {
  .post_txt {
    min-height: 160px;
    background: #fff;
    border-radius: 0.5em;
    padding: 2em 0 0 2em;
    position: relative;

    @media #{$sp} {
      padding: 1.5em 1em 0;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 180px;
      height: 8px;
      background: $clr-org;
      border-top-left-radius: 0.5em;
    }

    .infosession_icon {
      position: absolute;
      right: 0.5em;
      top: 0.5em;

      &:hover {
        opacity: 1;
      }

      @media #{$sp} {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}