@charset "UTF-8";


/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
採用情報 recruitment
-------------------------------*/
.recruitment_kv {
  background: #fff url("../images/recruitment/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .recruitment_kv {
    background-position: -35em;
  }
}

@media only screen and (max-width: 640px) {
  .recruitment_kv {
    background: #fff url("../images/recruitment/kv_img_sp.png") right 4.5em no-repeat;
  }
}

.pages_contents .pages_sec_ttl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 1em;
  position: relative;
}

.pages_contents .pages_sec_ttl:before, .pages_contents .pages_sec_ttl:after {
  content: "";
  flex-grow: 1;
  height: 2px;
  background: #FF8000;
  display: block;
}

.pages_contents .pages_sec_ttl:before {
  margin-right: .4em;
}

.pages_contents .pages_sec_ttl:after {
  margin-left: .4em;
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_sec_ttl img {
    width: 30%;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_sec_ttl01 img {
    width: 50%;
  }
}

@media all and (-ms-high-contrast: none) {
  .pages_contents .pages_sec_ttl {
    align-items: flex-start;
  }
}

.pages_contents .pages_list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5em;
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_list {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_list tr {
    display: block;
    width: 100%;
  }
}

.pages_contents .pages_list th,
.pages_contents .pages_list td {
  font-weight: 400;
  line-height: 1.8em;
  vertical-align: middle;
  border: 1px solid #bfbfbf;
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_list th,
  .pages_contents .pages_list td {
    border-top: none;
  }
}

.pages_contents .pages_list th {
  width: 20%;
  height: 60px;
  text-align: center;
  background: #FF8000;
  color: #fff;
  padding: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pages_contents .pages_list th {
    width: 25%;
    line-height: 1.5em;
    padding: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_list th {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 1em 1.5em;
  }
}

.pages_contents .pages_list td {
  width: 85%;
  height: 60px;
  text-align: left;
  background: #ffffff;
  padding: 1em 2em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pages_contents .pages_list td {
    padding: 0.5em 1em;
    line-height: 1.5em;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_list td {
    width: 30%;
    padding: 1em 1.5em;
    font-size: 0.9rem;
    line-height: 1.5em;
  }
}

.pages_contents .pages_list .note {
  font-size: 0.9rem;
}

.pages_contents .pages_list .note a {
  border-bottom: 1px solid #000;
}

.pages_contents .pages_list .list_item {
  margin-left: 1.5em;
}

.pages_contents .pages_list .list_item li::before {
  content: '';
  display: inline-block;
  position: relative;
  top: -2px;
  left: -5px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #000;
}

.pages_contents .pages_list .des {
  padding: 0.8em 0;
}

.pages_contents .pages_sec_ttl02 {
  color: #FF8000;
  margin-bottom: 1.5em;
}

.pages_contents .pages_sec_ttl02:before {
  content: '';
  display: inline-block;
  position: relative;
  top: 2px;
  left: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #FF8000;
}

.pages_contents .pages_img {
  margin-bottom: 5em;
}

@media only screen and (max-width: 640px) {
  .pages_contents .pages_img {
    margin-bottom: 3em;
  }
}

.pages_contents .btm_txt_area {
  margin-bottom: 5em;
}

@media only screen and (max-width: 640px) {
  .pages_contents .btm_txt_area {
    margin-bottom: 3em;
  }
}

.pages_contents .btm_txt {
  margin-bottom: 1em;
}

.pages_contents .btm_txt02 {
  display: block;
  width: 485px;
  margin-bottom: 1em;
  background: #fff;
  color: #008e42;
  font-weight: bold;
  padding: 0.5em 1em;
  border: 2px solid #008e42;
}

@media only screen and (max-width: 640px) {
  .pages_contents .btm_txt02 {
    width: 85%;
    margin: 0 auto 1em auto;
    font-size: 0.8rem;
    text-align: center;
    padding: .5em 0;
  }
}

.pages_contents .btm_txt02:before {
  content: "";
  display: inline-block;
  background: url("../images/recruitment/dl_icon02.svg") no-repeat;
  width: 25px;
  height: 25px;
  background-size: contain;
  vertical-align: bottom;
  margin-right: 0.5em;
}

@media all and (-ms-high-contrast: none) {
  .pages_contents .btm_txt02:before {
    width: 35px;
    height: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .btm_txt02:before {
    width: 20px;
    height: 20px;
  }
}

@supports (-ms-ime-align: auto) {
  .pages_contents .btm_txt02:before {
    width: 40px;
    height: 35px;
    vertical-align: middle;
  }
}

.pages_contents .btm_txt02:hover {
  background: #008e42;
  color: #fff;
  opacity: 1;
}

.pages_contents .btm_txt02:hover:before {
  content: "";
  display: inline-block;
  background: url("../images/recruitment/dl_icon.svg") no-repeat;
  width: 25px;
  height: 25px;
  background-size: contain;
  vertical-align: bottom;
  margin-right: 0.5em;
}

@media all and (-ms-high-contrast: none) {
  .pages_contents .btm_txt02:hover:before {
    width: 35px;
    height: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .btm_txt02:hover:before {
    width: 20px;
    height: 20px;
  }
}

@supports (-ms-ime-align: auto) {
  .pages_contents .btm_txt02:hover:before {
    width: 40px;
    height: 35px;
    vertical-align: middle;
  }
}

.pages_contents .btm_txt03 {
  color: #ff0000;
  border: 2px solid #ff0000;
}

.pages_contents .btm_txt03:before {
  background: url("../images/recruitment/dl_icon03.svg") no-repeat;
}

.pages_contents .btm_txt03:hover {
  background: #ff0000;
}

.pages_contents .pages_sec_ttl02 {
  margin-bottom: 1em;
}

.pages_contents .btn {
  display: block;
  width: 550px;
  margin: 0 auto;
  padding: 1.5em 0;
  background: #008e42;
  border: 1px solid #008e42;
  border-radius: 5em;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pages_contents .btn {
    width: 70%;
  }
}

@media only screen and (max-width: 640px) {
  .pages_contents .btn {
    width: 90%;
    padding: 1em 0 1.3em 0;
  }
  .pages_contents .btn img {
    width: 70%;
  }
}

.pages_contents .btn::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2em;
  width: 15px;
  height: 15px;
  margin-top: -5px;
  border-right: 1.5px solid #fff;
  border-bottom: 1.5px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (max-width: 640px) {
  .pages_contents .btn::after {
    right: 1.5em;
    width: 10px;
    height: 10px;
  }
}

.pages_contents .flow_list {
  max-width: 820px;
  margin: 0 auto 3em auto;
}

.pages_contents .flow_list_item {
  height: 90px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  box-shadow: 0 2px 2px rgba(204, 204, 204, 0.5);
  border-radius: .8em;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .pages_contents .flow_list_item {
    height: 60px;
    margin-bottom: 2em;
  }
}

.pages_contents .flow_list_item:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 50px 0 50px;
  border-color: #FF8000 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -60%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 640px) {
  .pages_contents .flow_list_item:after {
    border-width: 15px 30px 0 30px;
    bottom: -55%;
  }
}

.pages_contents .flow_list_item:nth-last-of-type(1) {
  margin-bottom: 0;
}

.pages_contents .flow_list_item:nth-last-of-type(1):after {
  display: none;
}

.pages_contents .flow_list_item p {
  color: #FF8000;
  font-weight: 600;
  text-align: center;
}

.pages_contents .flow_list_item_txt {
  font-size: 20px;
  line-height: 1.3em;
}

@media only screen and (max-width: 640px) {
  .pages_contents .flow_list_item_txt {
    font-size: 17px;
  }
}

.pages_contents .flow_list_item_note {
  font-size: 20px;
}

@media only screen and (max-width: 640px) {
  .pages_contents .flow_list_item_note {
    font-size: 15px;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
インターンシップ internship KV
-------------------------------*/
.internship_kv {
  background: #fff url("../images/internship/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .internship_kv {
    padding-bottom: 11%;
    background-size: cover;
  }
}

@media only screen and (max-width: 640px) {
  .internship_kv {
    background: #fff url("../images/internship/kv_img_sp.png") right 4em no-repeat;
  }
}

.internship_kv .internship_kv_ttl_img {
  margin-left: 3em;
  display: block;
  width: 30%;
}

/*-------------------------------
インターンシップ internship
-------------------------------*/
/*一覧ページ*/
.post_item_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  margin-bottom: 3em;
}

@media only screen and (max-width: 640px) {
  .post_item_area {
    flex-direction: column;
    margin-bottom: 0.5em;
  }
}

.post_item {
  width: 48%;
  margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
  .post_item {
    width: 90%;
    margin: 0 auto 1.5em auto;
  }
}

.post_img {
  position: relative;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.post_img::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 8px;
  background: #FF8000;
  border-top-left-radius: 0.5em;
  z-index: 1;
}

.post_img img:hover {
  opacity: 1;
}

.post_img .end_icon {
  width: 330px;
  height: auto;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .post_img .end_icon {
    width: 65%;
  }
}

@media only screen and (max-width: 640px) {
  .post_img .end_icon {
    width: 70%;
  }
}

.post_txt {
  background: #fff;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 1.5em 2em 2em;
  min-height: 270px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .post_txt {
    min-height: 320px;
  }
}

@media only screen and (max-width: 640px) {
  .post_txt {
    padding: 1em;
    min-height: auto;
  }
}

.post_txt .ttl {
  font-size: 1rem;
  font-weight: bold;
  color: #FF8000;
}

@media only screen and (max-width: 640px) {
  .post_txt .ttl {
    min-width: 15%;
  }
}

.post_txt_t {
  margin-bottom: 0.5em;
}

.post_txt_t .ttl {
  font-size: 1.3rem;
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 640px) {
  .post_txt_t .ttl {
    font-size: 1.1rem;
  }
}

.post_txt_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.3em;
}

/* pager */
.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-links .page-numbers {
  display: block;
  margin: 0 0.8em;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 35px;
  text-align: center;
  position: relative;
  border: 1px solid #FF8000;
  border-radius: 50%;
  color: #FF8000;
  font-size: 1.2rem;
  font-family: "Zen Maru Gothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .nav-links .page-numbers {
    margin: 0 0.4em;
  }
}

.nav-links .page-numbers:hover, .nav-links .page-numbers:active {
  color: #fff;
  background: #FF8000;
  border-radius: 50px;
  cursor: pointer;
}

.nav-links .current {
  background: #FF8000;
  color: #fff;
}

.nav-links .prev,
.nav-links .next {
  border: none;
}

.nav-links .prev:hover, .nav-links .prev:active,
.nav-links .next:hover,
.nav-links .next:active {
  color: #FF8000;
  background: none;
  border-radius: 0;
  cursor: pointer;
}

.nav-links .prev {
  border: none;
  position: relative;
}

.nav-links .prev:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  width: 15px;
  height: 15px;
  border-right: 2px solid #FF8000;
  border-bottom: 2px solid #FF8000;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

@media only screen and (max-width: 640px) {
  .nav-links .prev:after {
    left: 1em;
    width: 8px;
    height: 8px;
    margin-top: -5px;
  }
}

.nav-links .next {
  border: none;
  position: relative;
}

.nav-links .next:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
  width: 15px;
  height: 15px;
  border-right: 2px solid #FF8000;
  border-bottom: 2px solid #FF8000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (max-width: 640px) {
  .nav-links .next:after {
    right: 1em;
    width: 8px;
    height: 8px;
    margin-top: -5px;
  }
}

@media all and (-ms-high-contrast: none) {
  .nav-links .page-numbers {
    line-height: 45px;
  }
}

.prev a:hover, .prev a:active,
.next a:hover,
.next a:active {
  background: none;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

/*詳細ページ*/
.post_contents {
  max-width: 640px;
  margin: 0 auto;
}

.post_contents .post_contents_ttl {
  border-bottom: 1px solid #000;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

@media only screen and (max-width: 640px) {
  .post_contents .post_contents_ttl {
    padding-bottom: 0.5em;
  }
}

.post_contents .post_contents_ttl .ttl {
  color: #FF8000;
  line-height: 1.3em;
}

@media only screen and (max-width: 640px) {
  .post_contents .post_contents_ttl .ttl {
    font-size: 1.2rem;
    line-height: 1.4em;
  }
}

.post_contents .date {
  margin-bottom: 0.5em;
}

.post_contents .post_contents_img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin-bottom: 1em;
}

.post_contents .post_contents_txt {
  margin-bottom: 3em;
}

.post_contents .post_contents_txt:nth-last-of-type(1) {
  margin-bottom: 6em;
}

@media only screen and (max-width: 640px) {
  .post_contents .post_contents_txt:nth-last-of-type(1) {
    margin-bottom: 4em;
  }
}

.post_contents .post_contents_txt p {
  margin-bottom: 1em;
}

.post_contents .post_contents_txt a {
  color: #008e42;
  border-bottom: 1px solid #008e42;
}

.post_contents .post_contents_txt a:hover {
  opacity: 0.7;
}

.post_contents .btn {
  display: block;
  width: 250px;
  margin: 0 auto 1em auto;
  padding: 0.4em 0 0.5em 0;
  background: #008e42;
  border: 1px solid #008e42;
  border-radius: 5em;
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 640px) {
  .post_contents .btn {
    font-size: 1rem;
  }
}

.post_contents .btn::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2em;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border-right: 1.5px solid #fff;
  border-bottom: 1.5px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (max-width: 640px) {
  .post_contents .btn::after {
    right: 1em;
    width: 10px;
    height: 10px;
  }
}

.post_contents .btn:hover {
  background: #fff;
  color: #008e42;
}

.post_contents .btn:hover::after {
  border-right: 1.5px solid #008e42;
  border-bottom: 1.5px solid #008e42;
}

.post_contents .btn02 {
  background: #FF8000;
  border: 1px solid #FF8000;
}

.post_contents .btn02:hover {
  background: #fff;
  color: #FF8000;
}

.post_contents .btn02:hover::after {
  border-right: 1.5px solid #FF8000;
  border-bottom: 1.5px solid #FF8000;
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
会社説明会 infosession KV
-------------------------------*/
.infosession_kv {
  background: #fff url("../images/infosession/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .infosession_kv {
    background-position: -40em;
  }
}

@media only screen and (max-width: 640px) {
  .infosession_kv {
    height: 280px;
    background: #fff url("../images/infosession/kv_img_sp.png") -6em 4em no-repeat;
  }
}

.infosession_kv .infosession_kv_ttl_img {
  display: block;
  width: 35%;
  margin: 0.2em 0 0 1em;
}

/*-------------------------------
会社説明会 infosession
-------------------------------*/
.infosession_contents .post_txt {
  min-height: 160px;
  background: #fff;
  border-radius: 0.5em;
  padding: 2em 0 0 2em;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .infosession_contents .post_txt {
    padding: 1.5em 1em 0;
  }
}

.infosession_contents .post_txt::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 8px;
  background: #FF8000;
  border-top-left-radius: 0.5em;
}

.infosession_contents .post_txt .infosession_icon {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.infosession_contents .post_txt .infosession_icon:hover {
  opacity: 1;
}

@media only screen and (max-width: 640px) {
  .infosession_contents .post_txt .infosession_icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
新着情報 news
-------------------------------*/
.news_contents .post_item {
  width: 100%;
}

.news_contents .post_item .ttl {
  color: #000;
  font-size: 1rem;
  font-weight: 100;
  position: relative;
}

.news_contents .post_item .ttl:after {
  content: "";
  display: inline-block;
  height: 15px;
  border-right: 1px solid #000;
  margin: 0 0.5em;
  vertical-align: middle;
}

@media only screen and (max-width: 640px) {
  .news_contents .post_item .ttl:after {
    border-right: none;
  }
}

.news_contents .post_txt_item_area {
  border-bottom: 1px solid #000;
  position: relative;
}

.news_contents .post_txt_item_area:nth-last-of-type(1) {
  border-bottom: none;
}

.news_contents .post_txt_item_area .icon_new {
  content: "";
  display: inline-block;
  background: #FF8000;
  color: #fff;
  text-align: center;
  width: 65px;
  margin-right: 1em;
  position: absolute;
  top: 50%;
  left: 10%;
  margin-top: -12px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .news_contents .post_txt_item_area .icon_new {
    display: block;
    margin-top: 1em;
    position: static;
  }
}

@media only screen and (max-width: 640px) {
  .news_contents .post_txt_item_area .icon_new {
    display: block;
    margin-top: 1em;
    position: static;
  }
}

.news_contents .post_txt_item {
  max-width: 530px;
  margin: 0 auto;
  padding: 1em 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .news_contents .post_txt_item {
    margin: 0 auto 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .news_contents .post_txt_item {
    display: block;
    margin: 0;
    padding: 0.5em 0 1em 0;
  }
}

.news_contents .post_txt_item:nth-last-of-type(1) {
  border-bottom: none;
}

.news_contents .post_txt_item .txt {
  display: inline-block;
  font-size: 1rem;
  font-weight: 100;
}

.news_contents .post_txt {
  padding: 3em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 640px) {
  .news_contents .post_txt {
    padding: 1em 1.5em;
  }
}

.news_contents .ttl02 {
  margin-left: 5em;
}

@media only screen and (max-width: 640px) {
  .news_contents .ttl02 {
    margin-left: 0;
  }
}

.news_contents .news_link {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .news_contents .news_link {
    text-align: center;
  }
}

.news_contents .link_btn {
  display: inline-block;
  width: 290px;
  margin-bottom: 1em;
  padding: 0.5em 0 1em 0;
  border: 1px solid #2995CC;
  background: #2995CC;
  text-align: center;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .news_contents .link_btn {
    width: 95%;
    font-size: 1rem;
  }
}

.news_contents .link_btn::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  border-right: 1.5px solid #fff;
  border-bottom: 1.5px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.news_contents .link_btn02 {
  border: 1px solid #87C426;
  background: #87C426;
  margin-left: 0.8em;
}

@media only screen and (max-width: 640px) {
  .news_contents .link_btn02 {
    margin-left: 0;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
会社概要 Company
-------------------------------*/
.company_contents .pages_list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.company_contents .pages_list tr {
  border-bottom: 1px solid #000;
}

.company_contents .pages_list tr:nth-last-of-type(1) {
  border-bottom: none;
}

@media only screen and (max-width: 640px) {
  .company_contents .pages_list tr {
    display: block;
    width: 95%;
    margin: 0 auto;
  }
}

.company_contents .pages_list th,
.company_contents .pages_list td {
  font-weight: 400;
  line-height: 1.8em;
  vertical-align: middle;
  border: none;
}

@media only screen and (max-width: 640px) {
  .company_contents .pages_list th,
  .company_contents .pages_list td {
    font-size: 14px;
    border-top: none;
  }
}

.company_contents .pages_list th {
  width: 20%;
  height: 60px;
  text-align: left;
  background: none;
  font-weight: bold;
  color: #000;
  padding: 1em 1em 1em 3em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .company_contents .pages_list th {
    width: 25%;
    padding: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .company_contents .pages_list th {
    display: block;
    width: 100%;
    height: auto;
    padding: 0.5em 0 0 0;
  }
}

.company_contents .pages_list td {
  width: 85%;
  height: 60px;
  text-align: left;
  background: none;
  padding: 1em 2em;
}

@media only screen and (max-width: 640px) {
  .company_contents .pages_list td {
    width: 30%;
    height: auto;
    padding: 0 0 0.5em 0;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
キャリアパス／人事制度 careerpass KV
-------------------------------*/
.career_kv {
  background: #fff url("../images/careerpass/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_kv {
    background-position: -50em;
  }
}

@media only screen and (max-width: 640px) {
  .career_kv {
    background: #fff url("../images/careerpass/kv_img_sp.png") right 4em no-repeat;
  }
}

.career_kv .kv_ttl_img {
  display: block;
  width: 55%;
  margin: 0 0 0 0.5em;
}

/*-------------------------------
キャリアパス／人事制度 careerpass
-------------------------------*/
.career_contents .pages_sec_ttl {
  width: 73%;
  border-bottom: 2px solid #FF8000;
  padding-bottom: 1em;
  position: relative;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_contents .pages_sec_ttl {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .pages_sec_ttl {
    width: 100%;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
  }
}

.career_contents .pages_sec_ttl:before {
  display: none;
}

.career_contents .pages_sec_ttl:after {
  background: url("../images/careerpass/ttl_img.png") right no-repeat;
  width: 100%;
  height: auto;
  padding-bottom: 23%;
  background-size: contain;
  position: absolute;
  top: -.5em;
  right: -13.5em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_contents .pages_sec_ttl:after {
    top: -1em;
    right: -9em;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .pages_sec_ttl:after {
    top: -1.4em;
    right: -1em;
    padding-bottom: 20%;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .pages_sec_ttl img {
    width: 60%;
  }
}

.career_contents .pages_sec_ttl03 {
  border-bottom: 2px solid #FF8000;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

@media only screen and (max-width: 640px) {
  .career_contents .pages_sec_ttl03 {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .pages_sec_ttl03 img {
    width: 70%;
  }
}

.career_contents .pages_sec_ttl02 {
  font-weight: bold;
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 640px) {
  .career_contents .pages_sec_ttl02 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .post_txt_t {
    margin-bottom: 1em;
  }
}

.career_contents .sec01_item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.career_contents .sec01_item_txt {
  padding: 15px 0 0 15px;
  color: #FF8000;
}

.career_contents .sec01_item_btm {
  max-width: 800px;
  margin: 0 auto;
}

.career_contents .sec01_item_btm .sec_fx {
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec01_item_btm .sec_fx {
    flex-wrap: wrap;
  }
}

.career_contents .sec_col_item {
  width: 40%;
}

.career_contents .sec_col_item_top {
  margin-bottom: 85px;
}

.career_contents .sec_col_item_top .sec_col_item {
  position: relative;
}

.career_contents .sec_col_item_top .sec_col_item:nth-of-type(1):after {
  content: "";
  display: inline-block;
  background: url(../images/careerpass/arrow_n.png) center/contain no-repeat;
  width: 59px;
  height: 72px;
  position: absolute;
  top: 50%;
  right: -35%;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_top .sec_col_item:nth-of-type(1):after {
    width: 40px;
    height: 50px;
    right: -37%;
  }
}

.career_contents .sec_col_item_top .sec_col_item:nth-of-type(2) {
  margin-top: 70px;
}

.career_contents .sec_col_item_top .sec_col_item:nth-of-type(2):after {
  content: "";
  display: inline-block;
  background: url(../images/careerpass/arrow_b.png) center/contain no-repeat;
  width: 85px;
  height: 55px;
  position: absolute;
  bottom: -27%;
  right: 35%;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_top .sec_col_item:nth-of-type(2):after {
    width: 55px;
    bottom: -35%;
    right: 30%;
  }
}

.career_contents .sec_col_item_ttl {
  text-align: center;
  padding: .7em 0;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
  background: #FF8000;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_ttl {
    padding: .5em;
  }
}

.career_contents .sec_col_item_inner {
  padding: 30px;
  text-align: center;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em;
  border: 1px solid #FF8000;
  background: #ffefc9;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_inner {
    padding: .5em;
  }
}

.career_contents .sec_col_item .txt {
  text-align: left;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item .modal .modal__ttl {
    padding-left: 0;
  }
}

.career_contents .sec_col_item .modal .modal__ttl_area {
  width: 100%;
}

.career_contents .sec_col_item .modal .modal__ttl_img {
  margin-right: 15px;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item .modal .modal__ttl_img {
    margin-right: .2em;
  }
  .career_contents .sec_col_item .modal .modal__ttl_img img {
    width: 80% !important;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item .modal .modal__ttl img {
    width: 65%;
  }
}

.career_contents .sec_col_item_md {
  position: relative;
}

.career_contents .sec_col_item_md:after {
  content: "";
  display: inline-block;
  background: url(../images/careerpass/arrow_s.png) center/contain no-repeat;
  width: 74px;
  height: 69px;
  position: absolute;
  bottom: -20%;
  right: 45%;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_md:after {
    width: 55px;
    height: 50px;
    bottom: -10.5%;
    right: 42%;
  }
}

.career_contents .sec_col_item_md_ttl {
  padding: .5em 0;
  text-align: center;
  margin-bottom: 25px;
  border-radius: 2em;
  background: #FF8000;
}

.career_contents .sec_col_item_md_cat {
  padding: 1em 0;
  text-align: center;
  background: #ea2a2a;
  border-top-right-radius: .5em;
  border-top-left-radius: .5em;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_md_cat {
    padding: .5em 0;
  }
  .career_contents .sec_col_item_md_cat img {
    width: 45%;
  }
}

.career_contents .sec_col_item_md .sec_col_item {
  width: 100%;
  margin: 35px auto 95px auto;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_md .sec_col_item {
    margin: 2em auto 4em auto;
  }
}

.career_contents .sec_col_item_md .sec_col_item_inner {
  background: #ffe6e6;
  border: 1px solid #ea2a2a;
  padding-bottom: 0;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_md .sec_col_item_link {
    width: 45%;
    padding-top: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_md .sec_col_item .pop_img {
    width: 52%;
    padding-top: 1.1em;
  }
}

.career_contents .sec_col_item_md_sc:after {
  background: url(../images/careerpass/arrow_b.png) center/contain no-repeat;
  width: 85px;
  height: 55px;
  bottom: -15%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_contents .sec_col_item_md_sc:after {
    bottom: -12%;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_md_sc:after {
    bottom: -8%;
  }
}

.career_contents .sec_col_item_md_sc .sec_col_item_md_cat {
  background: #00804b;
}

.career_contents .sec_col_item_md_sc .sec_col_item_inner {
  flex-wrap: wrap;
  background: #e0e9cd;
  border: 1px solid #00804b;
}

.career_contents .sec_col_item_md_sc .sec_col_item_link {
  margin-bottom: 15px;
}

.career_contents .sec_col_item_bm_cat {
  text-align: center;
  padding: 1.5em;
  border-radius: .5em;
  background: #d7eaff;
  border: 1px solid #3f80bb;
}

@media only screen and (max-width: 640px) {
  .career_contents .sec_col_item_bm_cat {
    padding: 1em;
  }
  .career_contents .sec_col_item_bm_cat img {
    width: 18%;
  }
}

.career_contents_sec02 {
  padding: 3em 0 1em 0;
}

@media only screen and (max-width: 640px) {
  .career_contents_sec02 .pages_item_inner {
    width: 90%;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents_sec02 .pages_sec_ttl03 img {
    width: 40%;
  }
}

.career_contents_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.career_contents_item:nth-last-of-type(1) {
  margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
  .career_contents_item {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.career_contents_item .career_item_txt {
  width: 74%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_contents_item .career_item_txt {
    width: 100%;
    margin-right: 2em;
  }
}

@media only screen and (max-width: 640px) {
  .career_contents_item .career_item_txt {
    width: 100%;
  }
}

.career_contents_item .txt {
  line-height: 1.8em;
}

@media only screen and (max-width: 640px) {
  .career_contents_item .career_item_img {
    width: 40%;
  }
}

.career_img {
  max-width: 878px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_img {
    width: 85%;
  }
}

@media only screen and (max-width: 640px) {
  .career_img {
    width: 90%;
  }
}

.career_img:before {
  content: "";
  display: inline-block;
  width: 65px;
  height: 65px;
  background: url("../images/careerpass/txt.png") left no-repeat;
  animation: swing .9s infinite;
  position: absolute;
  top: 5em;
  left: -2em;
  z-index: 2;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_img:before {
    width: 60px;
    height: 60px;
    background-size: contain;
    top: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .career_img:before {
    width: 30px;
    height: 30px;
    background-size: contain;
    top: 1em;
    left: -0.5em;
  }
}

.career_img_bk {
  position: absolute;
  top: 17%;
  left: 9%;
  z-index: -1;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .career_img_bk {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .career_img_bk {
    width: 100%;
  }
}

.sec_career_area {
  margin-bottom: 60px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ff8000;
}

@media only screen and (max-width: 640px) {
  .sec_career_area {
    margin-bottom: 2em;
    padding-bottom: 2em;
  }
}

.sec_career_area:nth-last-of-type(1) {
  border: none;
  padding-bottom: 0;
  margin-bottom: 25px;
}

.sec_career {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sec_career {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .sec_career {
    flex-direction: column;
    margin-bottom: 0;
  }
  .sec_career figure {
    width: 45%;
  }
}

.sec_career_lt {
  width: 70%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sec_career_lt {
    width: 90%;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .sec_career_lt {
    width: 100%;
  }
}

.sec_career_lt_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #808080;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .sec_career_lt_item {
    padding-bottom: .5em;
    margin-bottom: 1em;
  }
}

.sec_career_name {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 640px) {
  .sec_career_name {
    margin-right: 1em;
  }
}

.sec_career_name_s {
  display: block;
  margin: -15px auto 0 50px;
  z-index: -1;
}

.sec_career_name_s_btm {
  margin: -25px auto 0 70px;
}

.sec_career_year {
  width: 138px;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  background: #FF8000;
  border-radius: 3em;
}

.sec_career_txt_btm {
  display: inline-block;
  position: relative;
  padding: 15px;
  margin: 15px auto 30px auto;
  border-radius: .5em;
  background: #ffefc9;
}

.sec_career_txt_btm:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 30%;
  left: -6%;
  border: 10px solid transparent;
  border-right: 35px solid #ffefc9;
  transform: rotate(25deg);
}

@media only screen and (max-width: 640px) {
  .sec_career_txt_btm:after {
    top: -10%;
    left: 55%;
    transform: rotate(40deg);
  }
}

.sec_career_btm {
  max-width: 580px;
  margin: 0 3.5em 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sec_career_btm {
    max-width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .sec_career_btm {
    max-width: 90%;
    margin: 0 auto 0 0;
  }
}

.sec_career_btm_item {
  width: 28%;
  height: 160px;
  padding: 15px 3px;
  margin-bottom: 30px;
  text-align: center;
  border-radius: .3em;
  border: 1px solid #ea2a2a;
  border-top: 7px solid #ea2a2a;
  background: #ffe6e6;
  position: relative;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sec_career_btm_item {
    height: auto;
  }
}

@media only screen and (max-width: 640px) {
  .sec_career_btm_item {
    width: 45%;
  }
}

.sec_career_btm_item:nth-of-type(1):before {
  content: "";
  display: inline-block;
  background: url("../images/careerpass/pop01_img02.png") center/contain no-repeat;
  width: 72px;
  height: 83px;
  position: absolute;
  bottom: -15%;
  right: -10%;
  z-index: 2;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm_item:nth-of-type(1):before {
    height: 70px;
    right: -25%;
  }
}

.sec_career_btm_item_cat {
  margin-bottom: 25px;
}

.sec_career_btm_item_cat_s {
  margin-bottom: 15px;
}

.sec_career_btm_item_txt {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 600;
  color: #ea2a2a;
  line-height: 1.3;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm_item_txt {
    font-size: 16px;
    font-size: 1rem;
  }
}

.sec_career_btm_item_txt .txt_b {
  display: block;
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm_item_txt .txt_b {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.sec_career_btm_item_txt .txt_s {
  display: block;
  font-size: 15px;
  font-size: 0.9375rem;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm_item_txt .txt_s {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.sec_career_btm_item_txt_s {
  width: 60%;
  padding: .2em 0;
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  background: #008e42;
  border-radius: 1em;
  position: absolute;
  bottom: -9%;
  left: 20%;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm_item_txt_s {
    width: 65%;
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.sec_career_btm .txt_red {
  background: #ea2a2a;
}

.sec_career_btm .txt_grn {
  color: #008e42;
}

.sec_career_btm .txt_arrow {
  position: relative;
  margin-bottom: 15px;
}

.sec_career_btm .txt_arrow:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 9px 0 9px;
  border-color: #FF8000 transparent transparent transparent;
  position: absolute;
  left: 43%;
  bottom: -40%;
}

.sec_career_btm .arrow {
  position: relative;
}

.sec_career_btm .arrow:after {
  content: "";
  display: block;
  background: url("../images/careerpass/sec02_arrow.png") center/contain no-repeat;
  width: 45px;
  height: 62px;
  position: absolute;
  top: 30%;
  right: -46px;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm .arrow:after {
    width: 30px;
    right: -31px;
  }
}

.sec_career_btm .arrow_sp {
  position: relative;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm .arrow_sp:after {
    content: "";
    display: block;
    background: url("../images/careerpass/sec02_arrow.png") center/contain no-repeat;
    width: 30px;
    height: 62px;
    position: absolute;
    top: 30%;
    right: -31px;
  }
}

.sec_career_btm .arrow_b {
  position: relative;
}

.sec_career_btm .arrow_b:before {
  content: "";
  display: block;
  background: url("../images/careerpass/sec02_arrow_b.png") center/contain no-repeat;
  width: 75px;
  height: 62px;
  position: absolute;
  top: 30%;
  left: -83px;
}

@media only screen and (max-width: 900px) {
  .sec_career_btm .arrow_b:before {
    display: none;
  }
}

.sec_career_btm .item_grn {
  border: 1px solid #008e42;
  border-top: 7px solid #008e42;
  background: #e0e9cd;
}

.sec_career_btm .icon_left:before {
  right: auto;
  left: -30%;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm .icon_left:before {
    left: auto;
  }
}

.sec_career_btm .icon_top:before {
  content: "";
  display: inline-block;
  background: url("../images/careerpass/pop05_img02.png") center/contain no-repeat;
  width: 81px;
  height: 80px;
  position: absolute;
  top: -45%;
  right: -30%;
  z-index: 2;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm .icon_top:before {
    height: 60px;
    right: 80%;
  }
}

.sec_career_btm .icon_top_lt:before {
  right: auto;
  top: 15%;
  left: -30%;
}

@media only screen and (max-width: 640px) {
  .sec_career_btm .icon_top_lt:before {
    top: 70%;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
わたしたちの想い hope KV
-------------------------------*/
.hope_kv {
  background: #fff url("../images/hope/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_kv {
    background-position: -20em;
  }
}

@media only screen and (max-width: 640px) {
  .hope_kv {
    height: 280px;
    background: #fff url("../images/hope/kv_img_sp.png") 1em 4em no-repeat;
    background-size: contain;
  }
}

.hope_kv .kv_ttl_img {
  display: block;
  width: 35%;
  margin: 0 0 0 5em;
}

/*-------------------------------
わたしたちの想い hope
-------------------------------*/
.hope_contents .hope_item {
  position: relative;
}

.hope_contents .hope_item:before {
  content: "";
  display: block;
  background: #FF8000;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:before {
    left: 10%;
  }
}

.hope_contents .hope_item_col {
  width: 390px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: .5em;
  margin-bottom: 30px;
  position: relative;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_contents .hope_item_col {
    padding: 1em 2em;
  }
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_col {
    width: 70%;
    margin: 0 0 2em auto;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_col {
    padding: 1em;
  }
}

.hope_contents .hope_item_col:before {
  content: "";
  position: absolute;
  top: 9%;
  border: 10px solid transparent;
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_col:before {
    top: 8%;
  }
}

.hope_contents .hope_item_col:nth-child(odd) {
  float: left;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_col:nth-child(odd) {
    float: none;
  }
}

.hope_contents .hope_item_col:nth-child(odd):before {
  border-left: 15px solid #fff;
  left: 100%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_contents .hope_item_col:nth-child(odd):before {
    transform: rotateY(180deg);
    left: -5%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_col:nth-child(odd):before {
    transform: rotateY(180deg);
    left: -9%;
  }
}

.hope_contents .hope_item_col:nth-child(odd) .year {
  right: -50%;
  margin-right: 5px;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_col:nth-child(odd) .year {
    right: 0;
    left: -43%;
  }
}

.hope_contents .hope_item_col:nth-child(even) {
  float: right;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_col:nth-child(even) {
    float: none;
  }
}

.hope_contents .hope_item_col:nth-child(even):before {
  border-right: 15px solid #fff;
  right: 100%;
}

.hope_contents .hope_item_col:nth-child(even) .year {
  left: -50%;
  margin-left: 10px;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_col:nth-child(even) .year {
    left: -43%;
    margin-left: 0;
  }
}

.hope_contents .hope_item_col:nth-of-type(1):before {
  top: 5%;
}

.hope_contents .hope_item_col:nth-of-type(1) .year {
  top: 0;
}

.hope_contents .hope_item_col:nth-of-type(2) {
  margin-top: 240px;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_col:nth-of-type(2) {
    margin-top: 0;
  }
}

.hope_contents .hope_item_col:nth-of-type(2):after {
  content: "";
  display: block;
  background: url("../images/hope/lr_img01.png") center/contain no-repeat;
  width: 100%;
  height: 230px;
  position: absolute;
  top: -75%;
  left: -10%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_contents .hope_item_col:nth-of-type(2):after {
    height: 170px;
    background-position: left;
    top: -55%;
    left: -35%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_col:nth-of-type(2):after {
    height: 100px;
    background-position: left;
    top: -55%;
    left: -40%;
  }
}

.hope_contents .hope_item_col:nth-of-type(7):before {
  top: 20%;
}

.hope_contents .hope_item_col:nth-of-type(7) .hope_item_ttl {
  font-size: 24px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_col:nth-of-type(7) .hope_item_ttl {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.hope_contents .hope_item_col figure {
  text-align: center;
  margin-bottom: 20px;
}

.hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(1):after {
  content: "";
  display: block;
  background: url("../images/hope/icon.png") center/contain no-repeat;
  width: 106px;
  height: 26px;
  position: absolute;
  top: 35%;
  right: -43%;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(1):after {
    right: 0;
    left: -39%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(1):after {
    width: 65px;
  }
}

.hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(3):after {
  content: "";
  display: block;
  background: url("../images/hope/icon.png") center/contain no-repeat;
  width: 106px;
  height: 26px;
  position: absolute;
  top: 50%;
  right: -43%;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(3):after {
    right: 0;
    left: -39%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(3):after {
    width: 65px;
  }
}

.hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(4) {
  margin-top: 180px;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(4) {
    margin-top: 8em;
  }
}

.hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(4):after {
  content: "";
  display: block;
  background: url("../images/hope/lr_img02.png") center/contain no-repeat;
  width: 100%;
  height: 130px;
  position: absolute;
  top: -35%;
  left: 0;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(4):after {
    height: 100px;
    top: -29%;
  }
}

.hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(5) {
  margin-top: 230px;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(5) {
    margin-top: 8em;
  }
}

.hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(5):after {
  content: "";
  display: block;
  background: url("../images/hope/lr_img03.png") center/contain no-repeat;
  width: 100%;
  height: 115px;
  position: absolute;
  top: -30%;
  left: 0;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(1) .hope_item_col:nth-of-type(5):after {
    height: 100px;
    top: -25%;
  }
}

.hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(1):after {
  content: "";
  display: block;
  background: url("../images/hope/lr_img04.png") center/contain no-repeat;
  width: 100%;
  height: 118px;
  position: absolute;
  top: -25%;
  left: -60%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(1):after {
    height: 100px;
    top: 20%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(1):after {
    height: 60px;
    top: 20%;
  }
}

.hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(2):before {
  top: 49%;
}

.hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(2):after {
  background: url("../images/hope/lr_img05.png") center/contain no-repeat;
  height: 168px;
  top: -45%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(2):after {
    height: 130px;
    background-position: left;
    top: 0;
    left: -28%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item:nth-of-type(2) .hope_item_col:nth-of-type(2):after {
    height: 70px;
    background-position: left;
    top: 0;
    left: -45%;
  }
}

.hope_contents .hope_item:nth-of-type(3) .hope_item_col:nth-of-type(2):after {
  background: none;
}

.hope_contents .hope_item:nth-of-type(3) .hope_item_col:nth-of-type(3):after {
  content: "";
  display: block;
  background: url("../images/hope/icon.png") center/contain no-repeat;
  width: 106px;
  height: 26px;
  position: absolute;
  bottom: 10%;
  right: -43%;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item:nth-of-type(3) .hope_item_col:nth-of-type(3):after {
    right: 0;
    left: -39%;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item:nth-of-type(3) .hope_item_col:nth-of-type(3):after {
    width: 65px;
  }
}

.hope_contents .hope_item_ttl {
  font-family: "Zen Maru Gothic", sans-serif;
  color: #FF8000;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: bold;
  font-feature-settings: "palt";
  line-height: 1.3;
  margin-bottom: 15px;
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_ttl {
    font-size: 21px;
    font-size: 1.3125rem;
    margin-bottom: .3em;
    font-weight: bold;
  }
}

.hope_contents .hope_item p {
  line-height: 1.5;
}

.hope_contents .hope_item .year {
  display: block;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border: 2px solid #FF8000;
  border-radius: .5em;
  background: #fff;
  position: absolute;
  top: 5%;
  z-index: 2;
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item .year {
    width: 35%;
  }
  .hope_contents .hope_item .year img {
    width: 75%;
  }
}

.hope_contents .hope_item .year_btm {
  top: auto;
  bottom: 0;
  right: 42%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .hope_contents .hope_item .year_btm {
    right: 0;
    left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item .year_btm {
    width: 24.5%;
    right: 0;
    left: -5px;
  }
  .hope_contents .hope_item .year_btm img {
    width: 90%;
  }
}

.hope_contents .hope_item_btm {
  text-align: center;
  padding: 65px 0;
  margin-top: 60px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: .5em;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .hope_contents .hope_item_btm {
    padding: 2em;
  }
}

.hope_contents .hope_item_btm:before {
  content: "";
  position: absolute;
  top: -5%;
  left: 47.5%;
  border: 25px solid transparent;
  border-bottom: 35px solid #fff;
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_btm:before {
    top: -7%;
    left: 43%;
  }
}

.hope_contents .hope_item_btm h4 {
  margin-bottom: 20px;
}

.hope_contents .hope_item_btm p {
  margin-bottom: 50px;
}

.hope_contents .hope_item_btm a {
  color: #FF8000;
}

@media only screen and (max-width: 640px) {
  .hope_contents .hope_item_btm a {
    display: block;
  }
}

.hope_contents .hope_item_btm a span {
  text-decoration: underline;
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
事業を知る　business KV
-------------------------------*/
.business_kv {
  background: #fff url("../images/business/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .business_kv {
    background-position: -26em;
  }
}

@media only screen and (max-width: 640px) {
  .business_kv {
    height: 280px;
    background: #fff url("../images/business/kv_img_sp.png") -7em 5em no-repeat;
  }
}

.business_kv .kv_ttl_img {
  display: block;
  width: 35%;
  margin: 0.2em 0 0 1em;
}

/*-------------------------------
事業を知る　business
-------------------------------*/
.business_contents .pages_item {
  border-radius: 5px;
  margin-bottom: 2em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .business_contents .pages_item {
    margin: 0 auto 2em auto;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_item {
    margin: 0 auto 1.5em auto;
    padding: 2em 0 1em;
  }
}

.business_contents .pages_item:before {
  border-top-left-radius: 5px;
}

.business_contents .pages_item .pages_sec_ttl:before {
  display: none;
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_item .pages_sec_ttl img {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_item .pages_sec_ttl:before {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_item .pages_item_inner {
    width: 90%;
  }
}

.business_contents .pages_item_lead {
  padding: 50px;
  margin-bottom: 2em;
  border-radius: 5px;
  background: #fff;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_item_lead {
    padding: 2em 1em;
  }
}

.business_contents .pages_item_lead p {
  margin-bottom: 1em;
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_item_lead p {
    text-align: left;
  }
}

.business_contents .pages_sec_ttl01 {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  color: #FF8000;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl01 {
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: bold;
    letter-spacing: -.04em;
    margin-bottom: 1em;
  }
  .business_contents .pages_sec_ttl01 img {
    width: 100% !important;
  }
}

.business_contents .post_txt_t {
  display: flex;
  flex-direction: column;
}

.business_contents .post_txt_t .txt {
  font-weight: bold;
  margin-bottom: 30px;
}

.business_contents .pages_sec_ttl {
  width: 70%;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 2px solid #ff8000;
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl {
    width: 100%;
  }
}

.business_contents .pages_sec_ttl:after {
  background: url("../images/business/icon01.png") right no-repeat;
  width: 100%;
  height: auto;
  padding-bottom: 27%;
  background-size: contain;
  position: absolute;
  top: -1em;
  right: -15em;
  margin-left: 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .business_contents .pages_sec_ttl:after {
    right: -11em;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl:after {
    top: 1em;
    right: -7px;
    padding-bottom: 15%;
  }
}

.business_contents .business_btm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}

@media only screen and (max-width: 640px) {
  .business_contents .business_btm {
    flex-direction: column;
  }
}

.business_contents .business_btm .business_item {
  width: 48%;
  padding: 2em 1.5em;
  margin-bottom: 2em;
  background: #fff;
  border-radius: 5px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .business_contents .business_btm .business_item {
    width: 100%;
    padding: 1em;
    margin-bottom: 1.5em;
  }
}

.business_contents .business_btm .business_item:before {
  content: "";
  display: inline-block;
  width: 170px;
  height: 8px;
  background: #FF8000;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
}

.business_contents .business_btm .business_item_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.business_contents .pages_sec_ttl04 {
  width: 100%;
  height: 80px;
  border-bottom: 2px solid #FF8000;
  padding: 0 0 0.2em 0.5em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .business_contents .pages_sec_ttl04 {
    height: 60px;
    margin-bottom: 1em;
    padding: 0 0 .5em 0;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl04 {
    width: 100%;
    padding: 0 0.5em .2em 1em;
    margin-bottom: 0.5em;
  }
}

.business_contents .pages_sec_ttl04 .ttl_icon {
  margin: 0 4em .5em 0;
  margin-right: 4em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .business_contents .pages_sec_ttl04 .ttl_icon {
    width: 17%;
    margin: 0 1em .5em 0;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl04 .ttl_icon {
    width: 20%;
    margin: 0 1em 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl04 .ttl_icon_s {
    width: 35%;
  }
}

.business_contents .pages_sec_ttl04 .ttl_icon02 {
  margin-right: 0.5em;
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl04 .ttl_icon02 {
    margin-right: 1.5em;
  }
}

@media only screen and (max-width: 640px) {
  .business_contents .pages_sec_ttl04 h3 img {
    width: 80%;
  }
}

.business_contents .open {
  display: block;
  width: 290px;
  height: 60px;
  margin: 0 auto;
  margin-top: auto;
  font-size: 20px;
  font-size: 1.25rem;
  color: #FF8000;
  font-weight: bold;
  text-align: center;
  border: 1px solid #FF8000;
  border-radius: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.business_contents .open:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10%;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  border-right: 2px solid #FF8000;
  border-bottom: 2px solid #FF8000;
  transform: rotate(-45deg);
}

.business_contents .open:hover {
  opacity: 0.7;
}

.business_contents .open_t {
  margin-top: 1.3em;
}

@media only screen and (max-width: 900px) {
  .business_contents .open_t {
    margin-top: 0;
  }
}

/* modalエリア */
.modal {
  display: none;
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modal .modal__bg {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: absolute;
  cursor: pointer;
}

.modal .modal__content {
  max-width: 1000px;
  width: 100%;
  max-height: 80%;
  padding: 2em;
  overflow-x: auto;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal .modal__content:before {
  border-top-left-radius: 4px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .modal .modal__content {
    width: 85%;
  }
}

@media only screen and (max-width: 640px) {
  .modal .modal__content {
    width: 85%;
    max-height: 75%;
    padding: 2em;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .modal .modal__img {
    width: 32%;
  }
}

.modal .modal__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

@media only screen and (max-width: 640px) {
  .modal .modal__item {
    flex-direction: column;
    align-items: center;
  }
}

.modal .modal__ttl_area {
  width: 65%;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .modal .modal__ttl_area {
    width: 100%;
  }
}

.modal .modal__ttl {
  border-bottom: 2px solid #FF8000;
  padding: 0 0 0.5em 0.5em;
  margin-bottom: 1em;
  position: relative;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .modal .modal__ttl {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .modal .modal__ttl {
    width: 100%;
    padding: 0 0.5em .5em 1em;
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 640px) {
  .modal .modal__ttl img {
    width: 80%;
  }
}

.modal .ttl_icon {
  margin-right: 1em;
}

@media only screen and (max-width: 640px) {
  .modal .ttl_icon {
    margin-right: 0;
  }
}

.modal .modal__ttl02 {
  font-size: 1.2rem;
  font-weight: bold;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .modal .modal__ttl02 {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 640px) {
  .modal .modal__ttl02 {
    font-size: 1rem;
    margin-bottom: 0.5em;
  }
}

.modal .modal_content_txt .ttl {
  font-size: 1.5rem;
  line-height: 2em;
}

.modal .modal_content_txt .note {
  font-size: 0.9rem;
}

.modal .modal_content_txt .txt_link {
  font-size: 14px;
  color: #666666;
  margin-top: .2em;
}

.modal .modal_content_txt .txt_link a {
  font-size: 0.9rem;
  word-break: break-all;
  color: #FF8000;
  border-bottom: 1px solid #FF8000;
}

.modal .a_link {
  display: inline-block;
  text-decoration: underline;
  margin: 0 .5em .5em 0;
  font-weight: 100;
}

.modal .a_link:hover {
  color: #FF8000;
}

.modal .close {
  display: block;
  background: url("../images/business/close.svg") no-repeat;
  width: 25px;
  height: 25px;
  background-size: contain;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  z-index: 2;
}

.modal .modal_logo {
  position: absolute;
  right: 0;
}

#modal01 .modal__ttl02 {
  padding-top: 1em;
}

@media only screen and (max-width: 640px) {
  #modal01 .modal__ttl02 {
    padding-top: 3em;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
学校関係者様へ school
-------------------------------*/
.school_contents .pages_item:before {
  display: none;
}

@media only screen and (max-width: 640px) {
  .school_contents .pages_inner {
    padding: 4em 0 2em 0;
  }
}

.school_contents .txt {
  margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
  .school_contents .txt {
    margin-bottom: 1em;
  }
}

.school_contents .pages_item_ttl {
  color: #FF8000;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.school_contents .pages_item_ttl:before {
  content: '';
  display: inline-block;
  position: relative;
  top: 2px;
  left: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #FF8000;
}

.school_contents .pages_txt_area {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding-top: 2em;
  margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
  .school_contents .pages_txt_area {
    margin-bottom: 1em;
  }
}

.pages_info .pages_txt_item {
  margin-bottom: 2em;
}

.pages_info .pages_txt_item:nth-last-of-type(1) {
  margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
  .pages_info .pages_txt_item {
    margin-bottom: 1em;
  }
}

.pages_info .pages_item_ttl02 {
  color: #000;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.pages_info .pages_item_ttl02:before {
  content: '';
  display: inline-block;
  position: relative;
  top: 2px;
  left: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #000;
}

.pages_info .icon {
  background: url("../images/school/icon01.svg") no-repeat;
  width: 18px;
  height: 18px;
  background-size: contain;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.3em;
}

.pages_info .icon:nth-of-type(2) {
  background: url("../images/school/icon02.svg") no-repeat;
}

.pages_info .icon:nth-of-type(3) {
  background: url("../images/school/icon03.svg") no-repeat;
}

.pages_info .txt {
  text-align: left;
}

@media only screen and (max-width: 640px) {
  .pages_info .txt a {
    line-height: 2.5em;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
社長メッセージ topmessage KV
-------------------------------*/
.msg_kv {
  background: #fff url("../images/topmessage/kv_img.png") no-repeat;
  background-size: cover;
  padding-bottom: 45%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv {
    background-position: 0;
    padding-bottom: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv {
    height: 280px;
    background: #fff url("../images/topmessage/kv_img.png") no-repeat;
    background-size: contain;
  }
}

.msg_kv .kv_ttl_img {
  display: block;
  width: 65%;
  margin: 0.2em 0 0 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv .kv_ttl_img {
    width: 45%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv .kv_ttl_img {
    display: inline-block;
    width: 25%;
    margin: 0.2em 0 0 0.5em;
  }
}

.msg_kv .msg_kv_txt {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 170px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv .msg_kv_txt {
    height: auto;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv .msg_kv_txt {
    height: auto;
  }
}

.msg_kv .msg_kv_ttl {
  display: block;
  font-size: 0.9rem;
  padding-bottom: 0.5em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv .msg_kv_ttl {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv .msg_kv_ttl {
    display: inline-block;
    font-size: 0.8rem;
    padding-bottom: 0;
    padding-right: 0.5em;
  }
}

.msg_kv .pages_kv_inner {
  display: flex;
  justify-content: center;
  padding-top: 3em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv .pages_kv_inner {
    align-items: center;
    padding: 1em 0 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv .pages_kv_inner {
    flex-direction: column;
    padding: 0.5em 0;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv .pages_kv_inner .pages_kv_ttl {
    font-size: 1.3rem;
    margin-bottom: 0.5em;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv .pages_kv_inner .pages_kv_txt {
    max-width: 55%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_kv .pages_kv_inner .pages_kv_txt {
    max-width: 85%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.msg_kv_hd {
  background: #fff url("../images/topmessage/hd/kv_img.png") center no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_kv_hd {
    background-size: cover;
  }
}

/*-------------------------------
社長メッセージ topmessage
-------------------------------*/
@media only screen and (max-width: 640px) {
  .msg_contents .pages_inner {
    padding: 3em 0;
  }
}

.msg_contents .pages_sec_ttl03 {
  margin-bottom: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_contents .pages_sec_ttl03 {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_contents .pages_sec_ttl03 {
    width: 60%;
    margin-bottom: 0.5em;
  }
}

.msg_contents .pages_sec_ttl04 {
  margin-bottom: 1em;
}

@media only screen and (max-width: 640px) {
  .msg_contents .pages_sec_ttl04 {
    width: 90%;
  }
}

.msg_contents .pages_sec_ttl05 {
  margin-bottom: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_contents .pages_sec_ttl05 {
    width: 75%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_contents .pages_sec_ttl05 {
    width: 70%;
  }
}

.msg_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7em;
  padding: 0 3em;
}

@media only screen and (max-width: 640px) {
  .msg_item {
    flex-direction: column;
    padding: 0 1.5em;
    margin-bottom: 3em;
  }
}

.msg_item02 {
  margin-bottom: 7em;
  padding: 0 3em;
}

@media only screen and (max-width: 640px) {
  .msg_item02 {
    padding: 0 1.5em;
    margin-bottom: 3em;
  }
}

.msg_item03 {
  margin-bottom: 3em;
  padding: 0 3em;
}

@media only screen and (max-width: 640px) {
  .msg_item03 {
    padding: 0 1.5em;
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 900px) {
  .msg_item_sec {
    flex-direction: column-reverse !important;
  }
}

.msg_item_bk {
  position: relative;
}

.msg_item_bk:after {
  content: "";
  display: block;
  width: 50%;
  height: 4px;
  background: #FF8000;
  border-radius: 1px;
  position: absolute;
  bottom: -3em;
  right: 0;
}

@media only screen and (max-width: 640px) {
  .msg_item_bk:after {
    bottom: -1.5em;
    height: 2px;
  }
}

.msg_item_bk02 {
  position: relative;
}

.msg_item_bk02:after {
  content: "";
  display: block;
  width: 50%;
  height: 4px;
  background: #FF8000;
  border-radius: 1px;
  position: absolute;
  bottom: -3em;
  left: 0;
}

@media only screen and (max-width: 640px) {
  .msg_item_bk02:after {
    bottom: -1.5em;
    height: 2px;
  }
}

.msg_txt_area {
  width: 47%;
}

@media only screen and (max-width: 640px) {
  .msg_txt_area {
    width: 100%;
  }
}

.msg_img {
  width: 50%;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_img {
    width: 45%;
    padding-top: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .msg_img {
    width: 90%;
    margin: 0 auto;
    padding-top: 1em;
  }
}

.msg_txt_area02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

@media only screen and (max-width: 640px) {
  .msg_txt_area02 {
    flex-direction: column;
  }
}

.msg_txt_area02 .txt {
  width: 48%;
  text-align: justify;
}

@media only screen and (max-width: 640px) {
  .msg_txt_area02 .txt {
    width: 100%;
    /*display: contents;*/
  }
}

.msg_img_area {
  width: 41%;
}

@media only screen and (max-width: 640px) {
  .msg_img_area {
    width: 100%;
  }
}

.msg_img02 {
  margin-bottom: 2.5em;
}

@media only screen and (max-width: 640px) {
  .msg_img02 {
    width: 90%;
    margin-bottom: 1em;
  }
}

.msg_img03 {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .msg_img03 {
    width: 60%;
    margin: 0 0 2em auto;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_contents_02 .msg_item {
    flex-direction: column;
  }
  .msg_contents_02 .msg_item .msg_txt_area {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_contents_02 .pages_sec_ttl03 {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_contents_02 .pages_sec_ttl04 {
    width: 70%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_contents_02 .pages_sec_ttl04 {
    width: 65%;
    margin-bottom: .5em;
  }
}

@media only screen and (max-width: 640px) {
  .msg_contents_02 .pages_sec_ttl04_sp {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .msg_contents_02 .pages_sec_ttl05 {
    width: 55%;
  }
}

@media only screen and (max-width: 640px) {
  .msg_contents_02 .pages_sec_ttl05 {
    width: 80%;
    margin-bottom: 1em;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
よくある質問 faq
-------------------------------*/
.faq_contents .txt {
  text-align: center;
  margin-bottom: 2em;
}

.faq_contents .txt .a_link {
  border-bottom: 1px solid #000;
}

.faq_contents .txt .a_link:hover {
  color: #008e42;
  border-bottom: 1px solid #008e42;
}

@media only screen and (max-width: 640px) {
  .faq_contents .txt {
    text-align: left;
  }
}

@media only screen and (max-width: 640px) {
  .faq_contents .pages_sec_ttl img {
    width: 65%;
  }
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_ttl img {
    width: 40%;
  }
}

.faq_contents .faq_item_area {
  margin-bottom: 3em;
}

.faq_contents .faq_item_area .faq_item {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto 1.5em auto;
  text-align: left;
  background: #fff;
  border-radius: 5px;
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area .faq_item {
    width: 95%;
  }
}

.faq_contents .faq_item_area .faq_item:before {
  content: "";
  display: inline-block;
  width: 170px;
  height: 5px;
  background: #ff8000;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
}

.faq_contents .faq_item_area input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.faq_contents .faq_item_area label {
  font-weight: 500;
  line-height: 60px;
  position: relative;
  display: block;
  padding: 0 0 0 2em;
  cursor: pointer;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area label {
    line-height: 1.8em;
    height: auto;
    font-size: 0.9rem;
    padding: 1em 2.5em 1em 2em;
    text-indent: -1em;
  }
}

.faq_contents .faq_item_area input[type=checkbox] + label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1.5em;
  margin-top: -10px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #FF8000;
  border-bottom: 2px solid #FF8000;
  transform: rotate(45deg);
  margin-right: 8px;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .faq_contents .faq_item_area input[type=checkbox] + label::after {
    right: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area input[type=checkbox] + label::after {
    right: 1em;
  }
}

.faq_contents .faq_item_area input[type=checkbox]:checked + label::after {
  transform: rotate(-135deg);
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .faq_contents .faq_item_area input[type=checkbox]:checked + label::after {
    margin-top: -5px;
  }
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area input[type=checkbox]:checked + label::after {
    margin-top: -5px;
  }
}

.faq_contents .faq_item_area input:checked ~ .faq_item_txt {
  max-height: 100em;
}

.faq_contents .faq_item_area .faq_item_txt {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  color: #000000;
}

.faq_contents .faq_item_area .faq_item_txt p {
  margin: 0 1em 2em 2em;
  font-weight: 500;
  text-indent: -1em;
  padding-left: 1em;
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area .faq_item_txt p {
    font-size: 0.9rem;
    margin: 0 2.5em 1em 1em;
  }
}

.faq_contents .faq_item_area .faq_item_txt .a_link {
  border-bottom: 1px solid #000;
}

.faq_contents .faq_item_area .faq_item_txt .a_link:hover {
  color: #008e42;
  border-bottom: 1px solid #008e42;
}

.faq_contents .faq_item_area .ttl {
  padding: 0 0 1em 3em;
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area .ttl {
    padding: 0 0 1em 1em;
  }
}

.faq_contents .faq_item_area .faq_item_img {
  margin-bottom: 4em;
  text-align: center;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .faq_contents .faq_item_area .faq_item_img {
    margin-bottom: 2em;
    padding: 0 3em;
  }
}

@media only screen and (max-width: 640px) {
  .faq_contents .faq_item_area .faq_item_img {
    padding: 0 1em;
  }
}

.faq_contents .pages_info {
  border-top: 1px solid #ccc;
  padding-top: 1.5em;
}

.faq_contents .pages_info .txt {
  text-align: left;
}

.faq_contents .pages_info .pages_item_ttl02 {
  margin-bottom: 0;
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
kv
-------------------------------*/
.staff_kv {
  background: #fff url("../images/person/staff01/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv {
    height: 425px;
    background-position: -10em;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv {
    background: #fff url("../images/person/staff01/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .pages_kv_inner {
    padding: 3em 0 0 1em;
  }
}

.staff_kv02 {
  background: #fff url("../images/person/staff02/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv02 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv02 {
    background: #fff url("../images/person/staff02/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv02 .staff_kv_txt {
  margin: 0 0 0 auto;
}

.staff_kv03 {
  background: #fff url("../images/person/staff03/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv03 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv03 {
    background: #fff url("../images/person/staff03/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv03 .staff_kv_txt {
  margin: 0 0 0 auto;
}

.staff_kv04 {
  background: #fff url("../images/person/staff04/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv04 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv04 {
    background: #fff url("../images/person/staff04/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv05 {
  background: #fff url("../images/person/staff05/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv05 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv05 {
    background: #fff url("../images/person/staff05/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv06 {
  background: #fff url("../images/person/staff06/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv06 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv06 {
    background: #fff url("../images/person/staff06/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv07 {
  background: #fff url("../images/person/staff07/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv07 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv07 {
    background: #fff url("../images/person/staff07/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv08 {
  background: #fff url("../images/person/staff08/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv08 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv08 {
    background: #fff url("../images/person/staff08/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv09 {
  background: #fff url("../images/person/staff09/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv09 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv09 {
    background: #fff url("../images/person/staff09/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

.staff_kv10 {
  background: #fff url("../images/person/staff10/kv_img.png") center no-repeat;
  background-size: cover;
  height: 565px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv10 {
    height: 425px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv10 {
    background: #fff url("../images/person/staff10/kv_img.png") center no-repeat;
    height: 280px;
    background-size: contain;
    background-position: top;
  }
}

/*-------------------------------
社員を知る 一覧
-------------------------------*/
@media only screen and (max-width: 640px) {
  .person_contents .post_item_area {
    flex-direction: row;
  }
}

.person_contents .post_txt {
  padding: 0;
  border-radius: 0.5em;
  position: relative;
  margin-bottom: 2em;
  transition: color .3s ease, background .3s ease, transform .3s ease, opacity .3s ease, border .3s ease, padding .3s ease, left .3s ease, bottom .3s ease, box-shadow .3s ease;
}

@media only screen and (max-width: 640px) {
  .person_contents .post_txt {
    width: 48%;
  }
}

.person_contents .post_txt::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 8px;
  background: #ff8000;
  border-top-left-radius: .5em;
  z-index: 2;
}

@media only screen and (max-width: 640px) {
  .person_contents .post_txt::before {
    width: 80px;
    height: 5px;
    border-top-left-radius: .2em;
  }
}

.person_contents .post_txt:hover {
  opacity: 1;
  transform: translateY(-8px);
  box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.09);
}

.person_contents .post_txt a:hover,
.person_contents .post_txt a:hover img,
.person_contents .post_txt .link:hover {
  opacity: 0.9;
}

.person_contents .post_txt .post_txt_area {
  padding: 0.5em 1em 1em;
}

.person_contents .post_txt .ttl {
  font-size: 1.3rem;
  color: #000;
  position: relative;
  z-index: 1;
  margin-bottom: 0.5em;
}

.person_contents .post_txt .ttl_img {
  display: block;
  margin: -0.7em 0 0 2em;
  position: relative;
  z-index: -1;
}

@media only screen and (max-width: 640px) {
  .person_contents .post_txt .ttl_img {
    margin: -1.2em 0 0 2em;
  }
}

.person_contents .post_txt .txt {
  line-height: 1.3em;
}

/*-------------------------------
社員を知る 詳細
-------------------------------*/
.staff_kv .staff_kv_txt {
  background: rgba(255, 255, 255, 0.8);
  max-width: 610px;
  padding: 1.5em;
  margin: 0 auto 0 0.5em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .staff_kv_txt {
    width: 50%;
    padding: 1em;
    margin: 0 auto 0 1em;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .staff_kv_txt {
    width: 100%;
    padding: 0.5em 1em;
    position: absolute;
    left: 0;
    bottom: -10px;
    background: #fff;
    margin: 0;
  }
}

.staff_kv .staff_kv_txt .pages_kv_inner {
  padding-top: 15em;
}

.staff_kv .staff_kv_txt02 {
  background: rgba(255, 255, 255, 0.8);
  max-width: 610px;
  padding: 1.5em;
  margin: 0 0.5em 0 auto;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .staff_kv_txt02 {
    width: 50%;
    padding: 1em;
    margin: 0 1em 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .staff_kv_txt02 {
    width: 100%;
    padding: 0.5em 1em;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
  }
}

.staff_kv .staff_kv_txt03 {
  background: rgba(255, 255, 255, 0.8);
  max-width: 500px;
  padding: 1.5em;
  margin: 0 0.5em 0 auto;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .staff_kv_txt03 {
    width: 45%;
    padding: 1em;
    margin: 0 1em 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .staff_kv_txt03 {
    width: 100%;
    padding: 0.5em 1em;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
  }
}

.staff_kv .pages_kv_inner {
  max-width: 1150px;
  margin: 0 auto;
}

.staff_kv .pages_kv_area {
  display: flex;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .pages_kv_area {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .pages_kv_area {
    display: block;
  }
}

.staff_kv .pages_kv_ttl {
  font-size: 1.6rem;
  line-height: 1.2em;
  margin-right: 1.5em;
  margin-bottom: 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .pages_kv_ttl {
    font-size: 1.4rem;
    margin-right: 0.5em;
    display: flex;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .pages_kv_ttl {
    font-size: 1.3rem;
    margin-right: 0;
    line-height: 1em;
  }
}

.staff_kv .pages_kv_ttl .pages_kv_ttl_img {
  display: block;
  margin-top: -0.3em;
  margin-left: 1.5em;
  position: relative;
  z-index: -1;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .pages_kv_ttl .pages_kv_ttl_img {
    width: 40%;
    vertical-align: middle;
    margin-top: 0;
    margin-left: 0.5em;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .pages_kv_ttl .pages_kv_ttl_img {
    display: inline-block;
    width: 30%;
    vertical-align: text-bottom;
    margin-left: 0.3em;
  }
}

.staff_kv .pages_kv_txt {
  border-bottom: 2px solid #FF8000;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .staff_kv .pages_kv_txt {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 640px) {
  .staff_kv .pages_kv_txt {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    color: #FF8000;
    font-family: "Zen Maru Gothic", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3em;
  }
}

.staff_kv .pages_kv_cat {
  line-height: 1.5em;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .staff_kv .pages_kv_cat {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .pages_sec_ttl03 {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .sf_contents .pages_sec_ttl03 {
    width: 75%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .pages_sec_ttl04 {
    width: 55%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .pages_sec_ttl05 {
    width: 90%;
  }
}

.sf_contents .pages_item {
  padding: 3em 0 1em 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .pages_item {
    padding: 2em 0 1em 0;
  }
}

@media only screen and (max-width: 640px) {
  .sf_contents .pages_item {
    padding: 2em 0 1em 0;
  }
}

.sf_contents .msg_item {
  margin-bottom: 4em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .msg_item {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 640px) {
  .sf_contents .msg_item {
    flex-direction: column-reverse;
    margin-bottom: 2em;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .msg_txt_area {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .msg_img {
    width: 60%;
    padding: 1em 0;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .sf_contents .msg_img02 {
    width: 100%;
    margin: 0 auto 1em auto;
  }
}

@media only screen and (max-width: 640px) {
  .sf_contents .msg_img02 {
    width: 100%;
    margin: 0 auto 1em auto;
  }
}

.sf_contents .staff_ttl {
  color: #ff8000;
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: .3em;
}

.sf_contents .sf_item {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 4em auto;
  background: #fff;
  padding: 3em;
  border-radius: 10px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .sf_contents .sf_item {
    padding: 1.5em;
  }
}

@media only screen and (max-width: 640px) {
  .sf_contents .msg_img {
    width: 80%;
    margin: 0;
    padding: 1em 0;
  }
}

.sf_contents .more_btn {
  width: 270px;
  height: 55px;
  line-height: 50px;
  font-size: 1.1rem;
  border: 2px solid #ff8000;
}

.sf_contents .sf_item_txt_area {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sf_contents .sf_item_txt_area {
    flex-direction: column;
  }
}

.sf_contents .sf_item_txt {
  width: 95%;
}

@media only screen and (max-width: 640px) {
  .sf_contents .sf_item_txt {
    width: 100%;
  }
}

.sf_contents .icon {
  /*width: 15%;*/
  margin-left: 1.5em;
}

@media only screen and (max-width: 640px) {
  .sf_contents .icon {
    width: 25%;
    margin: 0 0 0 auto;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
エントリー entry
-------------------------------*/
.entry_list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .entry_list {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .entry_list {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .entry_list tr {
    display: block;
    width: 100%;
  }
}

.entry_list th,
.entry_list td {
  font-weight: 400;
  line-height: 1.8em;
  vertical-align: middle;
  border: 1px solid #bfbfbf;
}

@media only screen and (max-width: 640px) {
  .entry_list th,
  .entry_list td {
    font-size: 14px;
    border-top: none;
  }
}

.entry_list th {
  width: 30%;
  height: 60px;
  text-align: center;
  background: #FF8000;
  color: #fff;
  padding: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .entry_list th {
    font-size: 0.9rem;
    padding: 1em 0.5em;
  }
}

@media only screen and (max-width: 640px) {
  .entry_list th {
    display: block;
    width: 100%;
    height: auto;
  }
}

.entry_list td {
  width: 85%;
  height: 60px;
  text-align: left;
  background: #ffffff;
  padding: 1em 2em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .entry_list td {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 640px) {
  .entry_list td {
    width: 20%;
    padding: 1em 0.5em;
  }
}

.entry_list .note {
  font-size: 0.9rem;
}

.entry_list .note a {
  border-bottom: 1px solid #000;
}

.entry_list input {
  padding: 0.8em;
  outline: none;
  border: 1px solid #000;
}

.entry_list .input {
  width: 90%;
}

@media only screen and (max-width: 640px) {
  .entry_list .input {
    height: 50px;
    width: 100%;
  }
}

.entry_list .input_s {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .entry_list .input_s {
    width: 100%;
    height: 50px;
  }
}

.entry_list .radio {
  margin-left: 2em;
}

@media only screen and (max-width: 640px) {
  .entry_list .radio {
    margin-left: 0.2em;
    vertical-align: middle;
  }
}

.entry_list .select {
  width: 40%;
  padding: .8em;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .entry_list .select {
    width: 100%;
  }
}

.entry_list .select02 {
  width: 20%;
  padding: .8em;
  background: #fff;
  margin: 0 0.5em;
}

.entry_list .select02:nth-of-type(1) {
  margin: 0 0.5em 0 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .entry_list .select02 {
    width: 20%;
  }
}

@media only screen and (max-width: 640px) {
  .entry_list .select02 {
    width: 25%;
    padding: .8em 0.1em;
    margin: 0 0.1em 0 0;
  }
}

@media only screen and (max-width: 320px) {
  .entry_list .select02 {
    padding: .8em 0.5em;
  }
}

.caution_txt {
  font-size: 1.1rem;
  padding: 0.5em 0;
  display: inline-block;
}

.entry_link {
  margin-bottom: 2em;
}

.submit_btn {
  width: 390px;
  margin: 0 auto;
  position: relative;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .submit_btn {
    width: 85%;
  }
}

.submit_btn:after {
  content: "";
  width: .6em;
  height: .6em;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4em;
  margin: auto;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 640px) {
  .submit_btn:after {
    border-top: none;
    border-right: none;
  }
}

.submit_btn input,
.submit_btn a {
  display: block;
  width: 350px;
  height: 70px;
  margin: 0 auto;
  line-height: 65px;
  color: #fff;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "游ゴシック体", "Yu Gothic", YuGothic, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  background: #008e42;
  border: 1px solid #008e42;
  border-radius: 5em;
  text-align: center;
  position: relative;
  transition: all .5s;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .submit_btn input,
  .submit_btn a {
    font-size: 1.3rem;
    width: 95%;
  }
}

@media only screen and (max-width: 640px) {
  .submit_btn input,
  .submit_btn a {
    font-size: 1.1rem;
    width: 85%;
    height: 55px;
    line-height: 55px;
    margin-bottom: 1em;
  }
}

.submit_btn input:hover,
.submit_btn a:hover {
  cursor: pointer;
  opacity: 0.8;
}

.input:focus,
.input_s:focus,
.select:focus,
.select02:focus {
  box-shadow: 0 0 7px #FF8000;
  border: 1px solid #FF8000;
}

/*--placeholder--*/
.select option:first-child {
  color: #ccc;
}

.select:not(:focus):invalid {
  color: #ccc;
}

/* Hidden placeholder */
select option[disabled]:first-child {
  display: none;
}

/* placeholder Chrome */
::-webkit-input-placeholder {
  color: #ccc;
  font-size: 1rem;
}

/* placeholder Firefox */
::-moz-placeholder {
  color: #ccc;
  font-size: 1rem;
}

/* placeholder IE */
:-ms-input-placeholder {
  color: #ccc;
  font-size: 1rem;
}

input::-webkit-input-placeholder {
  padding: 0 0 2em 0;
}

/*input[type="text"] {
		  box-sizing: border-box;
		  width: 100%;
		  font-size: 16px;
		}*/
input[type="text"],
input[type="email"],
textarea {
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: 100%;
}

.consent_area {
  width: 80%;
  margin: 0 auto 3em auto;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .consent_area {
    width: 90%;
    padding: 2em;
  }
}

@media only screen and (max-width: 640px) {
  .consent_area {
    width: 100%;
    padding: 2em 0;
    margin: 0 auto 1em auto;
  }
}

.consent_area .ttl {
  margin-bottom: 0.5em;
}

.consent_note {
  overflow: auto;
  height: 225px;
  padding: 2em 4em;
  background: #fff;
  color: #000;
  margin-bottom: 1em;
}

@media only screen and (max-width: 640px) {
  .consent_note {
    padding: 2em 1em;
  }
}

.consent_note p {
  color: #000;
  text-align: justify;
  font-size: 0.9rem;
  margin-bottom: 1em;
  line-height: 1.5em;
}

.checkbox {
  text-align: center;
}

.checkbox p {
  display: inline-block;
  font-size: 1rem;
}

/* css checkbox01 */
.checkbox_input {
  display: none;
}

.checkbox_parts {
  padding-left: 20px;
  position: relative;
  margin-right: 20px;
}

.checkbox_parts::before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 13px;
  height: 13px;
  border: 1px solid #999;
  border-radius: 3px;
  background: #dddddd;
}

.checkbox_input:checked + .checkbox_parts::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 5px;
  height: 7px;
  transform: rotate(40deg);
  border-bottom: 1.5px solid #333333;
  border-right: 1.5px solid #333333;
}

/*-------------------------------
エントリー確認画面 entry confirm
-------------------------------*/
.confirm_list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .confirm_list {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .confirm_list {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .confirm_list tr {
    display: block;
    width: 100%;
  }
  .confirm_list tr:nth-of-type(1) {
    border-top: 1px solid #bfbfbf;
  }
}

.confirm_list th,
.confirm_list td {
  font-weight: 400;
  line-height: 1.8em;
  vertical-align: middle;
  border: 1px solid #bfbfbf;
}

@media only screen and (max-width: 640px) {
  .confirm_list th,
  .confirm_list td {
    font-size: 14px;
    border-top: none;
  }
}

.confirm_list th {
  width: 30%;
  height: 60px;
  text-align: center;
  background: #fff;
  padding: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .confirm_list th {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 640px) {
  .confirm_list th {
    display: block;
    text-align: left;
    width: 100%;
    height: auto;
  }
}

.confirm_list td {
  width: 85%;
  height: 60px;
  text-align: left;
  background: #ffffff;
  padding: 1em 2em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .confirm_list td {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 640px) {
  .confirm_list td {
    width: 10%;
    padding: 1em;
  }
}

.confirm_list .note {
  font-size: 0.9rem;
}

.confirm_list .note a {
  border-bottom: 1px solid #000;
}

.confirm_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 640px) {
  .confirm_btn {
    flex-direction: column;
  }
}

@media only screen and (max-width: 640px) {
  .confirm_btn input[type=submit] {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .confirm_btn .submit_btn:after {
    right: 4em;
    margin-top: 22px;
  }
}

/*-------------------------------
エントリー完了画面 entry thanks
-------------------------------*/
.thanks_area {
  max-width: 480px;
  margin: 0 auto 4em auto;
}

.thanks_area .txt {
  font-size: 1.1rem;
  margin-bottom: 1em;
}

@media only screen and (max-width: 640px) {
  .thanks_area .txt {
    font-size: 1rem;
  }
}

.thanks_area .done {
  margin-bottom: 1em;
}

.thanks_area .done02 {
  margin-bottom: 2em;
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
学研版地域包括ケアシステム KV
-------------------------------*/
.care-system_kv {
  background: #fff url("../images/care-system/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .care-system_kv {
    background-position: -20em;
  }
}

@media only screen and (max-width: 640px) {
  .care-system_kv {
    height: 280px;
    background: #fff url("../images/care-system/kv_img_sp.png") right no-repeat;
    background-size: cover;
  }
}

.care-system_kv .kv_ttl_img {
  display: block;
  width: 35%;
  margin: 0 0 0 5em;
}

/*-------------------------------
学研版地域包括ケアシステム
-------------------------------*/
.care-system_contents .pages_sec_ttl03 {
  border-bottom: 2px solid #FF8000;
  margin-bottom: 25px;
  padding-bottom: 20px;
}

.care-system_contents .pages_item_txt {
  margin-bottom: 15px;
}

.care-system_contents .pages_item_md {
  margin-bottom: 45px;
}

.care-system_contents .pages_item_md .pages_item_txt {
  margin-bottom: 40px;
}

.care-system_contents .pages_item_btm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 60px 0;
}

@media only screen and (max-width: 900px) {
  .care-system_contents .pages_item_btm {
    margin: 0 0 2em 0;
  }
}

@media only screen and (max-width: 640px) {
  .care-system_contents .pages_item_btm {
    justify-content: space-between;
  }
}

.care-system_contents .pages_item_btm img {
  display: block;
  margin-right: 20px;
}

.care-system_contents .pages_item_btm img:nth-last-of-type(1) {
  margin: 0 0 0 20px;
}

@media only screen and (max-width: 900px) {
  .care-system_contents .pages_item_btm img:nth-last-of-type(1) {
    margin: 0;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .care-system_contents .pages_item_btm img {
    width: 29%;
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .care-system_contents .pages_item_btm img {
    width: 46%;
    margin: 0 0 1em 0;
  }
}

.care-system_contents .pages_item_link {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.care-system_contents .pages_item_link:after {
  content: "";
  display: block;
  width: 32%;
}

.care-system_contents .pages_item_link a {
  display: block;
  width: 23%;
  padding: 10px;
  margin-top: 15px;
  color: #fff;
  line-height: 1.5;
  background: #008e42;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .care-system_contents .pages_item_link a {
    width: 32%;
  }
}

@media only screen and (max-width: 640px) {
  .care-system_contents .pages_item_link a {
    width: 48%;
  }
}

.care-system_contents .pages_item_link a:after {
  content: "";
  display: inline-block;
  background: url("../images/care-system/icon.png") no-repeat;
  width: 11px;
  height: 11px;
  position: absolute;
  right: 5%;
  bottom: 5%;
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
動画で見る KV
-------------------------------*/
.movie_kv {
  background: #fff url("../images/movie/kv_img.jpg") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .movie_kv {
    background-position: -20em;
  }
}

@media only screen and (max-width: 640px) {
  .movie_kv {
    height: 280px;
    background: #fff url("../images/movie/kv_img_sp.jpg") right no-repeat;
    background-size: contain;
    background-position: bottom;
  }
}

.movie_kv .kv_ttl_img {
  display: block;
  width: 20%;
  margin: 0 0 0 10em;
}

/*-------------------------------
学研版地域包括ケアシステム
-------------------------------*/
.movie_contents .pages_sec_ttl03 {
  font-family: "Zen Maru Gothic", sans-serif;
  color: #FF8000;
  font-weight: 500;
  line-height: 1.3;
  border-bottom: 2px solid #FF8000;
  margin-bottom: 35px;
  padding-bottom: 25px;
  font-weight: 700;
  font-size: 30px;
  font-size: 1.875rem;
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_sec_ttl03 {
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 25px;
  }
}

.movie_contents .movieGuide {
  margin-bottom: 3em;
}

@media only screen and (max-width: 640px) {
  .movie_contents .movieGuide {
    margin-bottom: 2em;
  }
}

.movie_contents .movieGuide_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .movie_contents .movieGuide_list {
    justify-content: flex-start;
  }
}

.movie_contents .movieGuide_listItem {
  position: relative;
  display: inline-block;
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1024px) {
  .movie_contents .movieGuide_listItem {
    width: calc(308 / 1000 * 100%);
  }
  .movie_contents .movieGuide_listItem:not(:nth-child(3n)) {
    margin-right: calc(38 / 1000 * 100%);
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .movie_contents .movieGuide_listItem {
    width: calc(308 / 1000 * 100%);
  }
  .movie_contents .movieGuide_listItem:not(:nth-child(3n)) {
    margin-right: calc(38 / 1000 * 100%);
  }
}

@media only screen and (max-width: 640px) {
  .movie_contents .movieGuide_listItem {
    width: calc((100% - 4%) / 2);
    padding-bottom: 20px;
    margin-bottom: 15px;
  }
  .movie_contents .movieGuide_listItem:not(:nth-child(2n)) {
    margin-right: 4%;
  }
}

.movie_contents .movieGuide_listItem:link, .movie_contents .movieGuide_listItem:visited {
  color: #000;
}

.movie_contents .movieGuide_listItem:before {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1em;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background: url("../images/movie/arrow.svg") no-repeat center;
  background-size: contain;
}

.movie_contents .movieGuide_listItem figure {
  padding-bottom: 15px;
}

.movie_contents .movieGuide_listItem p {
  font-family: "Zen Maru Gothic", sans-serif;
  color: #FF8000;
  font-weight: 500;
  line-height: 1.3;
  font-weight: 700;
  color: #000;
  font-size: 20px;
  font-size: 1.25rem;
}

@media only screen and (max-width: 640px) {
  .movie_contents .movieGuide_listItem p {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.movie_contents .anchor {
  padding-top: 80px;
  margin-top: -80px;
}

.movie_contents .pages_item:last-of-type {
  margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .pages_item_inner {
    width: 90%;
  }
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item {
    margin-bottom: 3em;
  }
}

.movie_contents .pages_item .movieCont {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .movieCont {
    display: block;
  }
}

.movie_contents .pages_item .movieCont:not(:last-of-type) {
  margin-bottom: 60px;
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .movieCont:not(:last-of-type) {
    margin-bottom: 2em;
  }
}

.movie_contents .pages_item .movieCont_img {
  width: calc(450 / 910 * 100%);
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .movieCont_img {
    width: 100%;
    margin-bottom: 1em;
  }
}

.movie_contents .pages_item .movieCont_img a {
  position: relative;
  display: inline-block;
}

.movie_contents .pages_item .movieCont_img a:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../images/movie/play.svg") no-repeat center;
  z-index: 0;
}

.movie_contents .pages_item .movieCont_img a:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 0;
  transition: background-color .3s;
}

.movie_contents .pages_item .movieCont_img a:hover {
  opacity: 1;
}

.movie_contents .pages_item .movieCont_img a:hover * {
  opacity: 1;
}

.movie_contents .pages_item .movieCont_img a:hover:after {
  opacity: 1;
  animation-name: videoplay;
  animation-duration: .3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.movie_contents .pages_item .movieCont_img a:hover:before {
  opacity: 1;
  background: rgba(0, 0, 0, 0);
}

.movie_contents .pages_item .movieCont_txt {
  width: calc(430 / 910 * 100%);
  margin-left: calc(30 / 910 * 100%);
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .movieCont_txt {
    width: 100%;
    margin-left: 0;
  }
}

.movie_contents .pages_item .movieCont_ttl {
  font-family: "Zen Maru Gothic", sans-serif;
  color: #FF8000;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.06667;
  font-size: 30px;
  font-size: 1.875rem;
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .movieCont_ttl {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.movie_contents .pages_item .movieCont_ttl > span {
  display: inline-block;
  line-height: 1.23333;
  font-size: 20px;
  font-size: 1.25rem;
}

@media only screen and (max-width: 640px) {
  .movie_contents .pages_item .movieCont_ttl > span {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

#modalMovie .videoPlayer_wrap {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

#modalMovie .videoPlayer_wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 640px) {
  #modalMovie .modal__content {
    background-color: transparent;
    padding: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #modalMovie .close {
    display: none;
  }
}

@keyframes videoplay {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  80% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
センパイ社員アンケート kv
-------------------------------*/
.survey_kv {
  background: #fff url("../images/survey/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_kv {
    background-position: -35em;
  }
}

@media only screen and (max-width: 640px) {
  .survey_kv {
    height: 280px;
    background: #fff url("../images/survey/kv_img_sp.png") right no-repeat;
    background-size: cover;
  }
}

.survey_kv .kv_ttl_img {
  display: block;
  width: 35%;
  margin: 0 0 0 5em;
}

/*-------------------------------
センパイ社員アンケート
-------------------------------*/
.survey_contents .contents_inner {
  padding: 100px 0;
}

@media only screen and (max-width: 640px) {
  .survey_contents .contents_inner {
    padding: 3em 0;
  }
}

.survey_contents .pages_item {
  padding: 60px 0;
  text-align: center;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_contents .pages_item {
    padding: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .pages_item {
    padding: 3em 1em;
  }
}

.survey_contents .pages_item:before {
  width: 100%;
  border-top-right-radius: 8px;
}

.survey_contents .pages_item_ttl {
  margin-bottom: 60px;
}

@media only screen and (max-width: 640px) {
  .survey_contents .pages_item_ttl {
    margin-bottom: 2em;
  }
}

.survey_contents .sec_fx_js_ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.survey_contents .bubble {
  max-width: 665px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fffee6;
  border-radius: 2em;
  border: 3px solid #FF8000;
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #FF8000;
}

@media only screen and (max-width: 640px) {
  .survey_contents .bubble {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.3;
  }
}

.survey_contents .bubble:before, .survey_contents .bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
}

.survey_contents .bubble:before {
  border: 22px solid transparent;
  border-top-color: #FF8000;
  border-bottom: 0;
  border-left: 0;
  margin-left: 22px;
  margin-bottom: -22px;
  right: 40px;
}

.survey_contents .bubble:after {
  border: 18px solid transparent;
  border-top-color: #fffee6;
  border-bottom: 0;
  border-left: 0;
  margin-left: -18px;
  margin-bottom: -18px;
  right: 41px;
  bottom: 3px;
}

.survey_contents .survey_sec02 h3 {
  margin-bottom: 40px;
}

.survey_contents .survey_sec02 h4 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec02 h4 {
    margin-bottom: 1em;
  }
}

.survey_contents .survey_sec02 .sec_fx_js_ac {
  align-items: baseline;
  padding: 0 90px;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac {
    padding: 0;
  }
}

.survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-child(odd) figcaption {
  padding-top: 55px;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-child(odd) figcaption {
    padding-top: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-child(odd) img {
    width: 55%;
  }
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img {
    width: 50%;
  }
  .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-of-type(1), .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-of-type(2) {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 900px) and (max-width: 640px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-of-type(1), .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img:nth-of-type(2) {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac .pages_item_img img {
    width: 70%;
  }
}

.survey_contents .survey_sec02 .sec_fx_js_ac figcaption {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  color: #4d4d4d;
  font-weight: bold;
  line-height: 1.4;
  padding-top: 40px;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec02 .sec_fx_js_ac figcaption {
    font-size: 18px;
    font-size: 1.125rem;
    padding-top: 1em;
  }
}

.survey_contents .pages_item_col {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.survey_contents .pages_item_col dt,
.survey_contents .pages_item_col dd {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 37px;
  font-size: 2.3125rem;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 35px;
}

.survey_contents .pages_item_col dt:nth-last-of-type(1),
.survey_contents .pages_item_col dd:nth-last-of-type(1) {
  margin-bottom: 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_contents .pages_item_col dt,
  .survey_contents .pages_item_col dd {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .pages_item_col dt,
  .survey_contents .pages_item_col dd {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.survey_contents .pages_item_col dt {
  width: 13%;
  color: #FF8000;
}

@media only screen and (max-width: 640px) {
  .survey_contents .pages_item_col dt {
    width: 15%;
  }
}

.survey_contents .pages_item_col dd {
  width: 85%;
  color: #4d4d4d;
}

@media only screen and (max-width: 640px) {
  .survey_contents .pages_item_col dd {
    width: 75%;
  }
}

.survey_contents .pages_item_col_dt {
  letter-spacing: .2em;
}

.survey_contents .pages_item_col .dt_s,
.survey_contents .pages_item_col .dd_s {
  font-size: 30px;
  font-size: 1.875rem;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_contents .pages_item_col .dt_s,
  .survey_contents .pages_item_col .dd_s {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .pages_item_col .dt_s,
  .survey_contents .pages_item_col .dd_s {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.survey_contents .survey_sec03 .pages_item_col {
  max-width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec03 .pages_item_col {
    max-width: 100%;
  }
}

.survey_contents .survey_sec03 .pages_item_col:before {
  content: "";
  display: inline-block;
  background: url("../images/survey/sec03_icon.png") center/contain no-repeat;
  width: 100px;
  height: 92px;
  position: absolute;
  top: -15%;
  right: 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_contents .survey_sec03 .pages_item_col:before {
    top: -5%;
    width: 60px;
    height: 52px;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec03 .pages_item_col:before {
    top: -5%;
    width: 40px;
    height: 32px;
  }
}

.survey_contents ul {
  padding: 0 140px;
  height: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .survey_contents ul {
    max-width: 100%;
    padding: 0;
  }
}

.survey_contents ul li {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 34px;
  font-size: 2.125rem;
  color: #4d4d4d;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  position: relative;
  margin-bottom: 40px;
  text-indent: -1em;
  margin-left: 1em;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_contents ul li {
    font-size: 27px;
    font-size: 1.6875rem;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents ul li {
    font-size: 20px;
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
  .survey_contents ul li:nth-last-of-type(1) {
    margin-bottom: 0;
  }
}

.survey_contents ul li:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #FF8000;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: .3em;
}

@media only screen and (max-width: 640px) {
  .survey_contents ul li:before {
    width: 13px;
    height: 13px;
    margin-top: -5px;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec04 .pages_item_ttl {
    width: 90%;
    margin: 0 auto 2em;
  }
}

.survey_contents .survey_sec05 ul {
  padding: 0 170px;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec05 ul {
    padding: 0;
  }
}

.survey_contents .survey_sec05 ul:after {
  content: "";
  display: inline-block;
  background: url("../images/survey/sec05_img.png") right/contain no-repeat;
  width: 200px;
  height: 325px;
  position: absolute;
  top: 0;
  right: 20%;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec05 ul:after {
    right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec05 ul:after {
    position: relative;
    top: 1em;
    width: 150px;
    height: 225px;
  }
}

.survey_contents .survey_sec05 li {
  font-size: 27px;
  font-size: 1.6875rem;
  margin-bottom: 15px;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec05 li {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec06 .pages_item_ttl {
    width: 70%;
    margin: 0 auto 2em;
  }
}

.survey_contents .survey_sec06 .sec_fx_js {
  padding: 0 50px;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec06 .sec_fx_js {
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .survey_contents .survey_sec06 .sec_fx_js p {
    width: 50%;
    margin-bottom: 2em;
  }
  .survey_contents .survey_sec06 .sec_fx_js p:nth-last-of-type(1), .survey_contents .survey_sec06 .sec_fx_js p:nth-last-of-type(2) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec06 .sec_fx_js p {
    width: 45%;
    margin-bottom: 2em;
  }
  .survey_contents .survey_sec06 .sec_fx_js p:nth-last-of-type(1) {
    width: 35%;
    margin: 0 auto 0 3em;
  }
  .survey_contents .survey_sec06 .sec_fx_js p:nth-last-of-type(2) {
    margin-bottom: 0;
  }
}

.survey_contents .sec_fx_js {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .survey_contents .sec_fx_js {
    flex-wrap: wrap;
  }
}

.survey_contents .sec_fx_js:nth-of-type(1) {
  margin-bottom: 2em;
}

.survey_contents .sec_fx_js .pages_contents {
  width: 48.5%;
}

@media only screen and (max-width: 640px) {
  .survey_contents .sec_fx_js .pages_contents {
    width: 100%;
    margin-bottom: 2em;
  }
}

.survey_contents .sec_fx_js .pages_contents .pages_item {
  height: 100%;
  padding: 60px 30px 0 30px;
}

@media only screen and (max-width: 900px) {
  .survey_contents .sec_fx_js .pages_contents .pages_item {
    padding: 3em 1em 0 1em;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec07 .pages_item_ttl {
    width: 70%;
    margin: 0 auto 2em;
  }
}

.survey_contents .survey_sec07 .pages_item_col dt,
.survey_contents .survey_sec07 .pages_item_col dd {
  margin-bottom: 25px;
}

.survey_contents .survey_sec07 .pages_item_col dt {
  width: 25%;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec07 .pages_item_col dt {
    width: 20%;
  }
}

.survey_contents .survey_sec07 .pages_item_col dd {
  width: 75%;
  position: relative;
}

.survey_contents .survey_sec07 .pages_item_col dd:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 10%;
}

.survey_contents .survey_sec07 .pages_item_col dd:nth-of-type(1):after {
  background: url("../images/survey/sec07_icon.png") center/contain no-repeat;
  width: 103px;
  height: 51px;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec07 .pages_item_col dd:nth-of-type(1):after {
    width: 75px;
    height: 35px;
    right: 15%;
  }
}

.survey_contents .survey_sec07 .pages_item_col dd:nth-of-type(2):after {
  background: url("../images/survey/sec07_icon02.png") center/contain no-repeat;
  width: 59px;
  height: 58px;
  right: 15%;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec07 .pages_item_col dd:nth-of-type(2):after {
    width: 39px;
    height: 38px;
    right: 20%;
  }
}

.survey_contents .survey_sec07 .pages_item_col dd:nth-of-type(3):after {
  background: url("../images/survey/sec07_icon03.png") center/contain no-repeat;
  width: 60px;
  height: 58px;
  right: 15%;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec07 .pages_item_col dd:nth-of-type(3):after {
    width: 40px;
    height: 38px;
    right: 20%;
  }
}

.survey_contents .survey_sec07 .pages_item_col .dt_s,
.survey_contents .survey_sec07 .pages_item_col .dd_s {
  margin-bottom: 15px;
}

.survey_contents .survey_sec07 .pages_item_col .dd_ss {
  font-size: 22px;
  font-size: 1.375rem;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec07 .pages_item_col .dd_ss {
    font-size: 16px;
    font-size: 1rem;
  }
}

.survey_contents .survey_sec07 .pages_item .bubble_item {
  width: 100%;
}

.survey_contents .survey_sec07 .pages_item .bubble_item .bubble {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  .survey_contents .survey_sec07 .pages_item .bubble_item .bubble {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.survey_contents .survey_sec07 .pages_item .bubble_item .bubble:before, .survey_contents .survey_sec07 .pages_item .bubble_item .bubble:after {
  bottom: auto;
  right: auto;
  transform: rotate(180deg);
}

.survey_contents .survey_sec07 .pages_item .bubble_item .bubble:before {
  top: -24px;
  left: 8px;
}

.survey_contents .survey_sec07 .pages_item .bubble_item .bubble:after {
  top: -17px;
  left: 49px;
}

.survey_contents .survey_sec08 .pages_item {
  position: relative;
}

.survey_contents .survey_sec08 .pages_item:after {
  content: "";
  display: inline-block;
  background: url("../images/survey/sec08_img.png") center/contain no-repeat;
  width: 207px;
  height: 155px;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec08 .pages_item:after {
    width: 180px;
    height: 95px;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec08 .pages_item_ttl {
    width: 60%;
    margin: 0 auto 2em;
  }
}

.survey_contents .survey_sec08 .pages_item_col dt,
.survey_contents .survey_sec08 .pages_item_col dd {
  margin-bottom: 25px;
}

.survey_contents .survey_sec08 .pages_item_col dt {
  width: 67%;
  color: #4d4d4d;
  display: flex;
  align-items: center;
}

.survey_contents .survey_sec08 .pages_item_col dt:after {
  content: "";
  flex-grow: 1;
  background: radial-gradient(circle farthest-side, #4d4d4d, #4d4d4d 30%, transparent 30%, transparent);
  background-size: 15px 15px;
  height: 15px;
  margin: 0 .2em;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec08 .pages_item_col dt:after {
    background-size: 10px 10px;
    height: 10px;
    margin: 0 .5em;
  }
}

.survey_contents .survey_sec08 .pages_item_col dd {
  width: 15%;
  color: #FF8000;
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec08 .pages_item_col dd {
    width: 10%;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec09,
  .survey_contents .survey_sec10 {
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 640px) {
  .survey_contents .survey_sec09 .pages_item_ttl,
  .survey_contents .survey_sec10 .pages_item_ttl {
    width: 75%;
    margin: 0 auto;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
/*-------------------------------
業界を知る　careindustry KV
-------------------------------*/
.careindustry_kv {
  background: #fff url("../images/careindustry/kv_img.png") right no-repeat;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .careindustry_kv {
    background-position: 0;
  }
}

@media only screen and (max-width: 640px) {
  .careindustry_kv {
    height: 280px;
    background: #fff url("../images/careindustry/kv_img_sp.png") -3em 4em no-repeat;
  }
}

.careindustry_kv .kv_ttl_img {
  display: block;
  width: 35%;
  margin: 0.2em 0 0 1em;
}

/*-------------------------------
業界を知る　careindustry
-------------------------------*/
.a_link {
  padding-top: 80px;
  margin-top: -80px;
}

.careindustry_contents .careindustry_item {
  padding: 70px;
  margin-bottom: 50px;
  background: #fff;
  border-radius: 5px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .careindustry_contents .careindustry_item {
    padding: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item {
    padding: 2em 1em;
  }
}

.careindustry_contents .careindustry_item_lead {
  padding: 50px;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_lead {
    padding: 2em 1em;
  }
}

.careindustry_contents .careindustry_item_box {
  padding: 30px;
  margin-bottom: 40px;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_box {
    padding: 1em;
  }
}

.careindustry_contents .careindustry_item_box h4 {
  border-bottom: 2px solid #FF8000;
  padding-bottom: 10px;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_box h4 img {
    width: 20%;
  }
}

.careindustry_contents .careindustry_item_box_list li a {
  display: inline-block;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  position: relative;
  margin-top: 15px;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_box_list li a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    text-indent: -.7em;
    margin: .5em 0 0 .7em;
  }
}

.careindustry_contents .careindustry_item_box_list li a::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  background: #FF8000;
  border-radius: 50%;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_box_list li a::before {
    width: 10px;
    height: 10px;
    margin-right: .1em;
  }
}

.careindustry_contents .careindustry_item_inner {
  margin-bottom: 60px;
}

.careindustry_contents .careindustry_item #sec03 .careindustry_item_inner {
  margin-bottom: 0;
}

.careindustry_contents .careindustry_item .ttl_line {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1;
  color: #FF8000;
  padding-left: 20px;
  margin-bottom: 20px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item .ttl_line {
    font-size: 18px;
    font-size: 1.125rem;
    padding-left: 1em;
    margin-bottom: .5em;
  }
  .careindustry_contents .careindustry_item .ttl_line img {
    width: 75%;
  }
}

.careindustry_contents .careindustry_item .ttl_line:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 100%;
  background: #FF8000;
  position: absolute;
  top: 3px;
  left: 0;
}

.careindustry_contents .careindustry_item_col {
  margin-bottom: 40px;
}

.careindustry_contents .careindustry_item_col:nth-last-of-type(1) {
  margin-bottom: 0;
}

.careindustry_contents .careindustry_item_col figure {
  text-align: center;
}

.careindustry_contents .careindustry_item_col figure p {
  text-align: left;
}

.careindustry_contents .careindustry_item_txt {
  margin-bottom: 30px;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_txt {
    margin-bottom: .5em;
  }
}

.careindustry_contents .careindustry_item_cat {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px 25px;
  margin-bottom: 20px;
  border-radius: 5em;
  background: #fff3db;
  line-height: 1.3;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_cat {
    font-size: 16px;
    font-size: 1rem;
    padding: .5em;
    margin-bottom: .5em;
    font-feature-settings: "palt";
  }
}

.careindustry_contents .careindustry_item_cat span {
  font-family: "Zen Maru Gothic", sans-serif;
}

.careindustry_contents .careindustry_item figcaption {
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: 15px;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item figcaption {
    font-size: 13px;
    font-size: 0.8125rem;
    margin-top: .5em;
    text-align: left;
  }
}

.careindustry_contents .careindustry_item_img img {
  margin-bottom: 20px;
}

.careindustry_contents .careindustry_item_img p span {
  display: block;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
}

.careindustry_contents .careindustry_item_des {
  margin-top: 40px;
}

.careindustry_contents .careindustry_item_btm_ttl {
  padding: 10px;
  margin: 30px 0 45px 0;
  text-align: center;
  border-radius: 5em;
  background: #FF8000;
}

.careindustry_contents .careindustry_item_btm_txt {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: -.05em;
}

@media only screen and (max-width: 640px) {
  .careindustry_contents .careindustry_item_btm_txt {
    font-size: 17px;
    font-size: 1.0625rem;
    text-align: left;
  }
}

/*-------------------------------
pages_kv キービジュアル
-------------------------------*/
.pages_kv {
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .pages_kv {
    height: 280px;
  }
}

.pages_kv_inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0 50px 0;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pages_kv_inner {
    max-width: 90%;
    padding-top: 3em;
  }
}

@media only screen and (max-width: 640px) {
  .pages_kv_inner {
    max-width: 90%;
    padding-top: 2em;
  }
}

.pages_kv_inner .pages_kv_ttl {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 1em;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pages_kv_inner .pages_kv_ttl {
    font-size: 1.7rem;
    margin-bottom: 0.5em;
  }
}

@media only screen and (max-width: 640px) {
  .pages_kv_inner .pages_kv_ttl {
    font-size: 1.5rem;
  }
}

.pages_kv_inner .pages_kv_ttl .pages_kv_ttl_img {
  margin-left: 1em;
  position: relative;
  z-index: -1;
}

.pages_kv_inner .pages_kv_txt {
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .pages_kv_inner .pages_kv_txt {
    font-size: 14px;
  }
}

.pages_inner {
  padding: 5em 0 4em 0;
}

@media only screen and (max-width: 640px) {
  .pages_inner {
    padding: 4em 0 3em 0;
  }
}

/*-------------------------------
pages common 
-------------------------------*/
.pages_item {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 5em auto;
  background: #fff;
  padding: 3em 0;
  border-radius: 10px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .pages_item {
    padding: 2em 0;
  }
}

.pages_item:before {
  content: "";
  display: inline-block;
  width: 170px;
  height: 8px;
  background: #FF8000;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 8px;
}

.pages_item:nth-last-of-type(1) {
  margin: 0 auto 2em auto;
}

.pages_item .pages_item_inner {
  max-width: 910px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pages_item .pages_item_inner {
    width: 90%;
  }
}

@media only screen and (max-width: 640px) {
  .pages_item .pages_item_inner {
    width: 85%;
  }
}
