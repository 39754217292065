@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
インターンシップ internship KV
-------------------------------*/
.internship_kv {
  background: #fff url("../images/internship/kv_img.png") right no-repeat;

  @media #{$tab} {
    padding-bottom: 11%;
    background-size: cover;
  }

  @media #{$sp} {
    background: #fff url("../images/internship/kv_img_sp.png") right 4em no-repeat;
  }

  .internship_kv_ttl_img {
    margin-left: 3em;
    display: block;
    width: 30%;
  }
}

/*-------------------------------
インターンシップ internship
-------------------------------*/
/*一覧ページ*/

.post_item_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  margin-bottom: 3em;

  @media #{$sp} {
    flex-direction: column;
    margin-bottom: 0.5em;
  }
}

.post_item {
  width: 48%;
  margin-bottom: 2em;

  @media #{$sp} {
    width: 90%;
    margin: 0 auto 1.5em auto;
  }
}

.post_img {
  position: relative;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 8px;
    background: $clr-org;
    border-top-left-radius: 0.5em;
    z-index: 1;
  }

  img:hover {
    opacity: 1;
  }

  .end_icon {
    width: 330px;
    height: auto;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @media #{$tab} {
      width: 65%;
    }

    @media #{$sp} {
      width: 70%;
    }
  }
}

.post_txt {
  background: #fff;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 1.5em 2em 2em;
  min-height: 270px;

  @media #{$tab} {
    min-height: 320px;

  }

  @media #{$sp} {
    padding: 1em;
    min-height: auto;
  }

  .ttl {
    font-size: 1rem;
    font-weight: bold;
    color: $clr-org;

    @media #{$sp} {
      min-width: 15%;
    }
  }
}

.post_txt_t {
  margin-bottom: 0.5em;

  .ttl {
    font-size: 1.3rem;
    margin-bottom: 0.5em;

    @media #{$sp} {
      font-size: 1.1rem;
    }
  }
}

.post_txt_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.3em;
}


/* pager */

.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .page-numbers {
    display: block;
    margin: 0 0.8em;
    padding: 0;
    width: 40px;
    height: 40px;
    line-height: 35px;
    text-align: center;
    position: relative;
    border: 1px solid $clr-org;
    border-radius: 50%;
    color: $clr-org;
    font-size: 1.2rem;
    font-family: $font_zenmaru;

    @media #{$sp} {
      margin: 0 0.4em;
    }

    &:hover,
    &:active {
      color: #fff;
      background: $clr-org;
      border-radius: 50px;
      cursor: pointer;
    }
  }

  .current {
    background: $clr-org;
    color: #fff;
  }

  .prev,
  .next {
    border: none;

    &:hover,
    &:active {
      color: $clr-org;
      background: none;
      border-radius: 0;
      cursor: pointer;
    }
  }

  .prev {
    border: none;
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -8px;
      width: 15px;
      height: 15px;
      border-right: 2px solid $clr-org;
      border-bottom: 2px solid $clr-org;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);

      @media #{$sp} {
        left: 1em;
        width: 8px;
        height: 8px;
        margin-top: -5px;
      }
    }
  }

  .next {
    border: none;
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -8px;
      width: 15px;
      height: 15px;
      border-right: 2px solid $clr-org;
      border-bottom: 2px solid $clr-org;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);

      @media #{$sp} {
        right: 1em;
        width: 8px;
        height: 8px;
        margin-top: -5px;
      }
    }
  }

  @media all and (-ms-high-contrast: none) {
    .page-numbers {
      line-height: 45px;
    }
  }
}

.prev a,
.next a {

  &:hover,
  &:active {
    background: none;
  }
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}


/*詳細ページ*/

.post_contents {
  max-width: 640px;
  margin: 0 auto;

  .post_contents_ttl {
    border-bottom: 1px solid #000;
    padding-bottom: 1em;
    margin-bottom: 1em;

    @media #{$sp} {
      padding-bottom: 0.5em;
    }

    .ttl {
      color: $clr-org;
      line-height: 1.3em;

      @media #{$sp} {
        font-size: 1.2rem;
        line-height: 1.4em;
      }
    }
  }

  .date {
    margin-bottom: 0.5em;
  }

  .post_contents_img {
    width: auto;
    height: auto;
    max-width: 100%;
    margin-bottom: 1em;
  }

  .post_contents_txt {
    margin-bottom: 3em;

    &:nth-last-of-type(1) {
      margin-bottom: 6em;

      @media #{$sp} {
        margin-bottom: 4em;
      }
    }

    p {
      margin-bottom: 1em;
    }

    a {
      color: $clr-grn;
      border-bottom: 1px solid $clr-grn;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .btn {
    display: block;
    width: 250px;
    margin: 0 auto 1em auto;
    padding: 0.4em 0 0.5em 0;
    background: $clr-grn;
    border: 1px solid $clr-grn;
    border-radius: 5em;
    font-size: 1.3rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: relative;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;

    @media #{$sp} {
      font-size: 1rem;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 2em;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      border-right: 1.5px solid #fff;
      border-bottom: 1.5px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);

      @media #{$sp} {
        right: 1em;
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      background: #fff;
      color: $clr-grn;
    }

    &:hover::after {
      border-right: 1.5px solid $clr-grn;
      border-bottom: 1.5px solid $clr-grn;
    }
  }

  .btn02 {
    background: $clr-org;
    border: 1px solid $clr-org;

    &:hover {
      background: #fff;
      color: $clr-org;
    }

    &:hover::after {
      border-right: 1.5px solid $clr-org;
      border-bottom: 1.5px solid $clr-org;
    }
  }
}