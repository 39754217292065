@charset "utf-8";
@import "../_variables";
@import "../_mixin";
/*-------------------------------
よくある質問 faq
-------------------------------*/

.faq_contents {
  .txt {
    text-align: center;
    margin-bottom: 2em;

    .a_link {
      border-bottom: 1px solid #000;

      &:hover {
        color: $clr-grn;
        border-bottom: 1px solid $clr-grn;
      }
    }

    @media #{$sp} {
      text-align: left;
    }
  }

  .pages_sec_ttl img {
    @media #{$sp} {
      width: 65%;
    }
  }

  .faq_ttl img {
    @media #{$sp} {
      width: 40%;
    }
  }

  .faq_item_area {
    margin-bottom: 3em;

    .faq_item {
      position: relative;
      overflow: hidden;
      width: 100%;
      margin: 0 auto 1.5em auto;
      text-align: left;
      background: #fff;
      border-radius: 5px;

      @media #{$sp} {
        width: 95%;

      }

      &:before {
        content: "";
        display: inline-block;
        width: 170px;
        height: 5px;
        background: #ff8000;
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 5px;
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    label {
      font-weight: 500;
      line-height: 60px;
      position: relative;
      display: block;
      padding: 0 0 0 2em;
      cursor: pointer;
      position: relative;

      @media #{$sp} {
        line-height: 1.8em;
        height: auto;
        font-size: 0.9rem;
        padding: 1em 2.5em 1em 2em;
        text-indent: -1em;
      }
    }

    input[type=checkbox]+label::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1.5em;
      margin-top: -10px;
      width: 10px;
      height: 10px;
      border-right: 2px solid $clr-org;
      border-bottom: 2px solid $clr-org;
      transform: rotate(45deg);
      margin-right: 8px;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;

      @media #{$tab} {
        right: 1em;
      }

      @media #{$sp} {
        right: 1em;
      }
    }

    input[type=checkbox]:checked+label::after {
      transform: rotate(-135deg);

      @media #{$tab} {
        margin-top: -5px;
      }

      @media #{$sp} {
        margin-top: -5px;
      }
    }

    input:checked~.faq_item_txt {
      max-height: 100em;
    }

    .faq_item_txt {
      overflow: hidden;
      max-height: 0;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
      color: #000000;

      p {
        margin: 0 1em 2em 2em;
        font-weight: 500;
        text-indent: -1em;
        padding-left: 1em;

        @media #{$sp} {
          font-size: 0.9rem;
          margin: 0 2.5em 1em 1em;
        }
      }

      .a_link {
        border-bottom: 1px solid #000;

        &:hover {
          color: $clr-grn;
          border-bottom: 1px solid $clr-grn;
        }
      }
    }

    .ttl {
      padding: 0 0 1em 3em;

      @media #{$sp} {
        padding: 0 0 1em 1em;
      }
    }

    .faq_item_img {
      margin-bottom: 4em;
      text-align: center;

      @media #{$tab} {
        margin-bottom: 2em;
        padding: 0 3em;
      }

      @media #{$sp} {
        padding: 0 1em;
      }
    }
  }

  .pages_info {
    border-top: 1px solid #ccc;
    padding-top: 1.5em;

    .txt {
      text-align: left;
    }

    .pages_item_ttl02 {
      margin-bottom: 0;
    }
  }
}