@charset "utf-8";
@import "../_variables";
@import "../_mixin";
/*-------------------------------
事業を知る　business KV
-------------------------------*/

.business_kv {
  background: #fff url("../images/business/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -26em;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/business/kv_img_sp.png") -7em 5em no-repeat;
  }

  .kv_ttl_img {
    display: block;
    width: 35%;
    margin: 0.2em 0 0 1em;
  }
}

/*-------------------------------
事業を知る　business
-------------------------------*/

.business_contents {
  .pages_item {
    border-radius: 5px;
    margin-bottom: 2em;

    @media #{$tab} {
      margin: 0 auto 2em auto;
    }

    @media #{$sp} {
      margin: 0 auto 1.5em auto;
      padding: 2em 0 1em;
    }

    &:before {
      border-top-left-radius: 5px;
    }

    .pages_sec_ttl {
      &:before {
        display: none;
      }

      img {
        @media #{$sp} {
          width: 80%;
        }
      }

      &:before {
        @media #{$sp} {
          display: none;
        }
      }
    }

    .pages_item_inner {
      @media #{$sp} {
        width: 90%;
      }
    }

    &_lead {
      padding: 50px;
      margin-bottom: 2em;
      border-radius: 5px;
      background: #fff;
      text-align: center;

      @media #{$sp} {
        padding: 2em 1em;
      }

      p {
        margin-bottom: 1em;

        @media #{$sp} {
          text-align: left;
        }
      }
    }
  }

  .pages_sec_ttl01 {
    font-family: $font_zenmaru;
    @include fz(30);
    color: $clr-org;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 40px;

    @media #{$sp} {
      @include fz(21);
      font-weight: bold;
      letter-spacing: -.04em;
      margin-bottom: 1em;

      img {
        width: 100% !important;
      }
    }
  }

  .post_txt_t {
    display: flex;
    flex-direction: column;

    .txt {
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .pages_sec_ttl {
    width: 70%;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 2px solid #ff8000;

    @media #{$sp} {
      width: 100%;
    }

    &:after {
      background: url("../images/business/icon01.png")right no-repeat;
      width: 100%;
      height: auto;
      padding-bottom: 27%;
      background-size: contain;
      position: absolute;
      top: -1em;
      right: -15em;
      margin-left: 0;

      @media #{$tab} {
        right: -11em;
      }

      @media #{$sp} {
        top: 1em;
        right: -7px;
        padding-bottom: 15%;
      }
    }
  }

  .business_btm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;

    @media #{$sp} {
      flex-direction: column;
    }

    .business_item {
      width: 48%;
      padding: 2em 1.5em;
      margin-bottom: 2em;
      background: #fff;
      border-radius: 5px;
      position: relative;

      @media #{$sp} {
        width: 100%;
        padding: 1em;
        margin-bottom: 1.5em;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 170px;
        height: 8px;
        background: $clr-org;
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 5px;
      }
    }

    .business_item_inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .pages_sec_ttl04 {
    width: 100%;
    height: 80px;
    border-bottom: 2px solid $clr-org;
    padding: 0 0 0.2em 0.5em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    position: relative;

    @media #{$tab} {
      height: 60px;
      margin-bottom: 1em;
      padding: 0 0 .5em 0;
    }

    @media #{$sp} {
      width: 100%;
      padding: 0 0.5em .2em 1em;
      margin-bottom: 0.5em;
    }

    .ttl_icon {
      margin: 0 4em .5em 0;
      margin-right: 4em;

      @media #{$tab} {
        width: 17%;
        margin: 0 1em .5em 0;
      }

      @media #{$sp} {
        width: 20%;
        margin: 0 1em 0 0;
      }

      &_s {
        @media #{$sp} {
          width: 35%;
        }
      }
    }

    .ttl_icon02 {
      margin-right: 0.5em;

      @media #{$sp} {
        margin-right: 1.5em;
      }
    }

    h3 img {
      @media #{$sp} {
        width: 80%;
      }
    }
  }

  .open {
    display: block;
    width: 290px;
    height: 60px;
    margin: 0 auto;
    margin-top: auto;
    @include fz(20);
    color: $clr-org;
    font-weight: bold;
    text-align: center;
    border: 1px solid $clr-org;
    border-radius: 5em;
    @include fx_jc_cc;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10%;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-right: 2px solid $clr-org;
      border-bottom: 2px solid $clr-org;
      transform: rotate(-45deg);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .open_t {
    margin-top: 1.3em;

    @media #{$tab_s} {
      margin-top: 0;
    }
  }
}

/* modalエリア */
.modal {
  display: none;
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .modal__bg {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    position: absolute;
    cursor: pointer;
  }

  .modal__content {
    max-width: 1000px;
    width: 100%;
    max-height: 80%;
    padding: 2em;
    overflow-x: auto;
    border-radius: 5px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:before {
      border-top-left-radius: 4px;
    }

    @media #{$tab} {
      width: 85%;
    }

    @media #{$sp} {
      width: 85%;
      max-height: 75%;
      padding: 2em;
    }
  }

  .modal__img {
    @media #{$tab} {
      width: 32%;
    }
  }

  .modal__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5em;

    @media #{$sp} {
      flex-direction: column;
      align-items: center;
    }
  }

  .modal__ttl_area {
    width: 65%;
    position: relative;

    @media #{$sp} {
      width: 100%;
    }
  }

  .modal__ttl {
    border-bottom: 2px solid $clr-org;
    padding: 0 0 0.5em 0.5em;
    margin-bottom: 1em;
    position: relative;
    display: flex;
    align-items: center;

    @media #{$tab} {
      margin-bottom: 1em;
    }

    @media #{$sp} {
      width: 100%;
      padding: 0 0.5em .5em 1em;
      margin-bottom: 0.5em;
    }

    img {
      @media #{$sp} {
        width: 80%;
      }
    }
  }

  .ttl_icon {
    margin-right: 1em;

    @media #{$sp} {
      margin-right: 0;
    }
  }

  .modal__ttl02 {
    font-size: 1.2rem;
    font-weight: bold;

    @media #{$tab} {
      font-size: 1.1rem;
    }

    @media #{$sp} {
      font-size: 1rem;
      margin-bottom: 0.5em;
    }
  }

  .modal_content_txt {
    .ttl {
      font-size: 1.5rem;
      line-height: 2em;
    }

    .note {
      font-size: 0.9rem;
    }

    .txt_link {
      font-size: 14px;
      color: #666666;
      margin-top: .2em;

      a {
        font-size: 0.9rem;
        word-break: break-all;
        color: $clr-org;
        border-bottom: 1px solid $clr-org;
      }
    }
  }

  .a_link {
    display: inline-block;
    text-decoration: underline;
    margin: 0 .5em .5em 0;
    font-weight: 100;

    &:hover {
      color: $clr-org;
    }
  }

  .close {
    display: block;
    background: url("../images/business/close.svg")no-repeat;
    width: 25px;
    height: 25px;
    background-size: contain;
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    z-index: 2;
  }

  .modal_logo {
    position: absolute;
    right: 0;
  }
}

#modal01 {
  .modal__ttl02 {
    padding-top: 1em;

    @media #{$sp} {
      padding-top: 3em;
    }
  }
}