@charset "utf-8";
@import "../_variables";
@import "../_mixin";
/*-------------------------------
エントリー entry
-------------------------------*/

.entry_list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;

  @media #{$tab} {
    margin-bottom: 3em;
  }

  @media #{$sp} {
    margin-bottom: 3em;
  }

  tr {
    @media #{$sp} {
      display: block;
      width: 100%;
    }
  }

  th,
  td {
    font-weight: 400;
    line-height: 1.8em;
    vertical-align: middle;
    border: 1px solid #bfbfbf;

    @media #{$sp} {
      font-size: 14px;
      border-top: none;
    }
  }

  th {
    width: 30%;
    height: 60px;
    text-align: center;
    background: $clr-org;
    color: #fff;
    padding: 1em;

    @media #{$tab} {
      font-size: 0.9rem;
      padding: 1em 0.5em;
    }

    @media #{$sp} {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  td {
    width: 85%;
    height: 60px;
    text-align: left;
    background: #ffffff;
    padding: 1em 2em;

    @media #{$tab} {
      font-size: 0.9rem;
    }

    @media #{$sp} {
      width: 20%;
      padding: 1em 0.5em;
    }
  }

  .note {
    font-size: 0.9rem;

    a {
      border-bottom: 1px solid #000;
    }
  }

  input {
    padding: 0.8em;
    outline: none;
    border: 1px solid #000;
  }

  .input {
    width: 90%;

    @media #{$sp} {
      height: 50px;
      width: 100%;
    }
  }

  .input_s {
    width: 40%;

    @media #{$sp} {
      width: 100%;
      height: 50px;
    }
  }

  .radio {
    margin-left: 2em;

    @media #{$sp} {
      margin-left: 0.2em;
      vertical-align: middle;
    }
  }

  .select {
    width: 40%;
    padding: .8em;
    background: #fff;

    @media #{$sp} {
      width: 100%;
    }
  }

  .select02 {
    width: 20%;
    padding: .8em;
    background: #fff;
    margin: 0 0.5em;

    &:nth-of-type(1) {
      margin: 0 0.5em 0 0;
    }

    @media #{$tab} {
      width: 20%;
    }

    @media #{$sp} {
      width: 25%;
      padding: .8em 0.1em;
      margin: 0 0.1em 0 0;
    }

    @media #{$sp_s} {
      padding: .8em 0.5em;
    }
  }
}

.caution_txt {
  font-size: 1.1rem;
  padding: 0.5em 0;
  display: inline-block;
}

.entry_link {
  margin-bottom: 2em;

  @media #{$sp} {}
}

.submit_btn {
  width: 390px;
  margin: 0 auto;
  position: relative;
  margin: 0 auto;

  @media #{$sp} {
    width: 85%;
  }

  &:after {
    content: "";
    width: .6em;
    height: .6em;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4em;
    margin: auto;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media #{$sp} {
      border-top: none;
      border-right: none;
    }
  }

  input,
  a {
    display: block;
    width: 350px;
    height: 70px;
    margin: 0 auto;
    line-height: 65px;
    color: #fff;
    font-family: $font_noto;
    font-size: 1.5rem;
    font-weight: bold;
    background: #008e42;
    border: 1px solid #008e42;
    border-radius: 5em;
    text-align: center;
    position: relative;
    transition: all .5s;

    @media #{$tab} {
      font-size: 1.3rem;
      width: 95%;
    }

    @media #{$sp} {
      font-size: 1.1rem;
      width: 85%;
      height: 55px;
      line-height: 55px;
      margin-bottom: 1em;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.input:focus,
.input_s:focus,
.select:focus,
.select02:focus {
  box-shadow: 0 0 7px $clr-org;
  border: 1px solid $clr-org;
}


/*--placeholder--*/

.select option:first-child {
  color: #ccc;
}

.select:not(:focus):invalid {
  color: #ccc;
}


/* Hidden placeholder */

select option[disabled]:first-child {
  display: none;
}


/* placeholder Chrome */

::-webkit-input-placeholder {
  color: #ccc;
  font-size: 1rem;
}


/* placeholder Firefox */

::-moz-placeholder {
  color: #ccc;
  font-size: 1rem;
}


/* placeholder IE */

:-ms-input-placeholder {
  color: #ccc;
  font-size: 1rem;
}

input::-webkit-input-placeholder {
  padding: 0 0 2em 0;
}


/*input[type="text"] {
		  box-sizing: border-box;
		  width: 100%;
		  font-size: 16px;
		}*/

input[type="text"],
input[type="email"],
textarea {
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: 100%;
}

.consent_area {
  width: 80%;
  margin: 0 auto 3em auto;

  @media #{$tab} {
    width: 90%;
    padding: 2em;
  }

  @media #{$sp} {
    width: 100%;
    padding: 2em 0;
    margin: 0 auto 1em auto;
  }

  .ttl {
    margin-bottom: 0.5em;
  }
}

.consent_note {
  overflow: auto;
  height: 225px;
  padding: 2em 4em;
  background: #fff;
  color: #000;
  margin-bottom: 1em;

  @media #{$sp} {
    padding: 2em 1em;
  }

  p {
    color: #000;
    text-align: justify;
    font-size: 0.9rem;
    margin-bottom: 1em;
    line-height: 1.5em;
  }
}

.checkbox {
  text-align: center;

  p {
    display: inline-block;
    font-size: 1rem;
  }
}


/* css checkbox01 */

.checkbox_input {
  display: none;
}

.checkbox_parts {
  padding-left: 20px;
  position: relative;
  margin-right: 20px;
}

.checkbox_parts::before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 13px;
  height: 13px;
  border: 1px solid #999;
  border-radius: 3px;
  background: #dddddd;
}

.checkbox_input:checked+.checkbox_parts::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 5px;
  height: 7px;
  transform: rotate(40deg);
  border-bottom: 1.5px solid #333333;
  border-right: 1.5px solid #333333;
}


/*-------------------------------
エントリー確認画面 entry confirm
-------------------------------*/

.confirm_list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5em;

  @media #{$tab} {
    margin-bottom: 3em;
  }

  @media #{$sp} {
    margin-bottom: 3em;
  }

  tr {
    @media #{$sp} {
      display: block;
      width: 100%;

      &:nth-of-type(1) {
        border-top: 1px solid #bfbfbf;
      }
    }
  }

  th,
  td {
    font-weight: 400;
    line-height: 1.8em;
    vertical-align: middle;
    border: 1px solid #bfbfbf;

    @media #{$sp} {
      font-size: 14px;
      border-top: none;
    }
  }

  th {
    width: 30%;
    height: 60px;
    text-align: center;
    background: #fff;
    padding: 1em;

    @media #{$tab} {
      font-size: 0.9rem;
    }

    @media #{$sp} {
      display: block;
      text-align: left;
      width: 100%;
      height: auto;
    }
  }

  td {
    width: 85%;
    height: 60px;
    text-align: left;
    background: #ffffff;
    padding: 1em 2em;

    @media #{$tab} {
      font-size: 0.9rem;
    }

    @media #{$sp} {
      width: 10%;
      padding: 1em;
    }
  }

  .note {
    font-size: 0.9rem;

    a {
      border-bottom: 1px solid #000;
    }
  }
}

.confirm_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  @media #{$sp} {
    flex-direction: column;
  }

  input[type=submit] {
    @media #{$sp} {
      margin-bottom: 1em;
    }
  }

  .submit_btn:after {
    @media #{$sp} {
      right: 4em;
      margin-top: 22px;
    }
  }
}


/*-------------------------------
エントリー完了画面 entry thanks
-------------------------------*/

.thanks_area {
  max-width: 480px;
  margin: 0 auto 4em auto;

  .txt {
    font-size: 1.1rem;
    margin-bottom: 1em;

    @media #{$sp} {
      font-size: 1rem;
    }
  }

  .done {
    margin-bottom: 1em;
  }

  .done02 {
    margin-bottom: 2em;
  }
}