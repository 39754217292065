@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
業界を知る　careindustry KV
-------------------------------*/
.careindustry_kv {
  background: #fff url("../images/careindustry/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: 0;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/careindustry/kv_img_sp.png") -3em 4em no-repeat;
  }

  .kv_ttl_img {
    display: block;
    width: 35%;
    margin: 0.2em 0 0 1em;
  }
}

/*-------------------------------
業界を知る　careindustry
-------------------------------*/
.a_link {
  padding-top: 80px;
  margin-top: -80px;
}

.careindustry_contents {
  .careindustry_item {
    padding: 70px;
    margin-bottom: 50px;
    background: #fff;
    border-radius: 5px;

    @media #{$tab} {
      padding: 3em;
    }

    @media #{$sp} {
      padding: 2em 1em;
    }

    &_lead {
      padding: 50px;
      text-align: center;

      @media #{$sp} {
        padding: 2em 1em;
      }
    }

    &_box {
      padding: 30px;
      margin-bottom: 40px;
      border: 1px solid #ccc;

      @media #{$sp} {
        padding: 1em;
      }

      h4 {
        border-bottom: 2px solid $clr_org;
        padding-bottom: 10px;

        @media #{$sp} {
          img {
            width: 20%;
          }
        }
      }

      &_list {
        li a {
          display: inline-block;
          @include fz(20);
          font-weight: 500;
          position: relative;
          margin-top: 15px;

          @media #{$sp} {
            @include fz(16);
            line-height: 1.5;
            text-indent: -.7em;
            margin: .5em 0 0 .7em;
          }

          &::before {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            background: $clr_org;
            border-radius: 50%;

            @media #{$sp} {
              width: 10px;
              height: 10px;
              margin-right: .1em;
            }
          }
        }
      }
    }

    &_inner {
      margin-bottom: 60px;
    }

    #sec03 {
      .careindustry_item_inner {
        margin-bottom: 0;
      }
    }

    .ttl_line {
      font-family: $font_zenmaru;
      @include fz(30);
      font-weight: bold;
      line-height: 1;
      color: $clr_org;
      padding-left: 20px;
      margin-bottom: 20px;
      position: relative;

      @media #{$sp} {
        @include fz(18);
        padding-left: 1em;
        margin-bottom: .5em;

        img {
          width: 75%;
        }
      }

      &:before {
        content: "";
        display: inline-block;
        width: 7px;
        height: 100%;
        background: $clr_org;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }

    &_col {
      margin-bottom: 40px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      figure {
        text-align: center;

        p {
          text-align: left;
        }
      }
    }

    &_txt {
      margin-bottom: 30px;

      @media #{$sp} {
        margin-bottom: .5em;
      }
    }

    &_cat {
      font-family: $font_zenmaru;
      @include fz(24);
      font-weight: bold;
      padding: 10px 25px;
      margin-bottom: 20px;
      border-radius: 5em;
      background: #fff3db;
      line-height: 1.3;

      @media #{$sp} {
        @include fz(16);
        padding: .5em;
        margin-bottom: .5em;
        font-feature-settings: "palt";
      }

      span {
        font-family: $font_zenmaru;
      }
    }

    figcaption {
      @include fz(14);
      margin-top: 15px;

      @media #{$sp} {
        @include fz(13);
        margin-top: .5em;
        text-align: left;
      }
    }

    &_img {
      img {
        margin-bottom: 20px;
      }

      p {
        span {
          display: block;
          @include fz(20);
          font-weight: 500;
        }
      }
    }

    &_des {
      margin-top: 40px;
    }

    &_btm {
      &_ttl {
        padding: 10px;
        margin: 30px 0 45px 0;
        text-align: center;
        border-radius: 5em;
        background: $clr_org;
      }

      &_txt {
        font-family: $font_zenmaru;
        @include fz(20);
        font-weight: bold;
        text-align: center;
        letter-spacing: -.05em;

        @media #{$sp} {
          @include fz(17);
          text-align: left;
        }
      }
    }
  }
}