@charset "utf-8";
@import "_format";
@import "_variables";
@import "_mixin";
@import 'object/init';


/*-------------------------------
pages_kv キービジュアル
-------------------------------*/
.pages_kv {
  width: 100%;
  // height: auto;
  position: relative;

  @media #{$sp} {
    height: 280px;
  }
}

.pages_kv_inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0 50px 0;

  @media #{$tab} {
    max-width: 90%;
    padding-top: 3em;
  }

  @media #{$sp} {
    max-width: 90%;
    padding-top: 2em;
  }

  .pages_kv_ttl {
    text-align: left;
    font-size: 2rem;
    margin-bottom: 1em;
    position: relative;
    z-index: 1;

    @media #{$tab} {
      font-size: 1.7rem;
      margin-bottom: 0.5em;
    }

    @media #{$sp} {
      font-size: 1.5rem;
    }

    .pages_kv_ttl_img {
      margin-left: 1em;
      position: relative;
      z-index: -1;
    }
  }

  .pages_kv_txt {
    font-weight: 500;

    @media #{$sp} {
      font-size: 14px;
    }
  }
}

.pages_inner {
  padding: 5em 0 4em 0;

  @media #{$sp} {
    padding: 4em 0 3em 0;
  }
}

/*-------------------------------
pages common 
-------------------------------*/
.pages_item {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 5em auto;
  background: #fff;
  padding: 3em 0;
  border-radius: 10px;
  position: relative;

  @media #{$sp} {
    padding: 2em 0;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 170px;
    height: 8px;
    background: $clr-org;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
  }

  &:nth-last-of-type(1) {
    margin: 0 auto 2em auto;
  }

  .pages_item_inner {
    max-width: 910px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    @media #{$tab} {
      width: 90%;
    }

    @media #{$sp} {
      width: 85%;
    }
  }
}