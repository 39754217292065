@charset "utf-8";
@import "../_variables";
@import "../_mixin";

/*-------------------------------
センパイ社員アンケート kv
-------------------------------*/
.survey_kv {
  background: #fff url("../images/survey/kv_img.png") right no-repeat;

  @media #{$tab} {
    background-position: -35em;
  }

  @media #{$sp} {
    height: 280px;
    background: #fff url("../images/survey/kv_img_sp.png") right no-repeat;
    background-size: cover;
  }

  .kv_ttl_img {
    display: block;
    width: 35%;
    margin: 0 0 0 5em;
  }
}

/*-------------------------------
センパイ社員アンケート
-------------------------------*/
.survey_contents {
  .contents_inner {
    padding: 100px 0;

    @media #{$sp} {
      padding: 3em 0;
    }
  }

  .pages_item {
    padding: 60px 0;
    text-align: center;

    @media #{$tab} {
      padding: 3em;
    }

    @media #{$sp} {
      padding: 3em 1em;
    }

    &:before {
      width: 100%;
      border-top-right-radius: 8px;
    }

    &_ttl {
      margin-bottom: 60px;

      @media #{$sp} {
        margin-bottom: 2em;
      }
    }
  }

  .sec_fx_js_ac {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bubble {
    max-width: 665px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #fffee6;
    border-radius: 2em;
    border: 3px solid $clr-org;
    font-family: $font_zenmaru;
    @include fz(20);
    font-weight: bold;
    color: $clr-org;

    @media #{$sp} {
      @include fz(16);
      line-height: 1.3;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
    }

    &:before {
      border: 22px solid transparent;
      border-top-color: $clr-org;
      border-bottom: 0;
      border-left: 0;
      margin-left: 22px;
      margin-bottom: -22px;
      right: 40px;
    }

    &:after {
      border: 18px solid transparent;
      border-top-color: #fffee6;
      border-bottom: 0;
      border-left: 0;
      margin-left: -18px;
      margin-bottom: -18px;
      right: 41px;
      bottom: 3px;
    }
  }

  .survey_sec02 {
    h3 {
      margin-bottom: 40px;
    }

    h4 {
      margin-bottom: 50px;

      @media #{$sp} {
        margin-bottom: 1em;
      }
    }

    .sec_fx_js_ac {
      align-items: baseline;
      padding: 0 90px;

      @media #{$tab_s} {
        flex-wrap: wrap;
      }

      @media #{$sp} {
        padding: 0;
      }

      .pages_item_img {
        &:nth-child(odd) {
          figcaption {
            padding-top: 55px;

            @media #{$sp} {
              padding-top: 1em;
            }
          }

          img {
            @media #{$sp} {
              width: 55%;
            }
          }
        }

        @media #{$tab_s} {
          width: 50%;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-bottom: 3em;

            @media #{$sp} {
              margin-bottom: 1em;
            }
          }
        }

        img {
          @media #{$sp} {
            width: 70%;
          }
        }
      }

      figcaption {
        font-family: $font_zenmaru;
        @include fz(28);
        color: $clr_gry;
        font-weight: bold;
        line-height: 1.4;
        padding-top: 40px;

        @media #{$sp} {
          @include fz(18);
          padding-top: 1em;
        }
      }
    }
  }

  .pages_item {
    &_col {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;

      dt,
      dd {
        font-family: $font_zenmaru;
        @include fz(37);
        font-weight: bold;
        line-height: 1.3;
        text-align: left;
        margin-bottom: 35px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        @media #{$tab} {
          @include fz(30);
        }

        @media #{$sp} {
          @include fz(22);
        }
      }

      dt {
        width: 13%;
        color: $clr-org;

        @media #{$sp} {
          width: 15%;
        }
      }

      dd {
        width: 85%;
        color: #4d4d4d;

        @media #{$sp} {
          width: 75%;
        }
      }

      &_dt {
        letter-spacing: .2em;
      }

      .dt_s,
      .dd_s {
        @include fz(30);

        @media #{$tab} {
          @include fz(25);
        }

        @media #{$sp} {
          @include fz(18);
        }
      }
    }
  }

  .survey_sec03 {
    .pages_item_col {
      max-width: 80%;
      margin: 0 auto;

      @media #{$tab_s} {
        max-width: 100%;
      }

      &:before {
        content: "";
        display: inline-block;
        background: url("../images/survey/sec03_icon.png") center/contain no-repeat;
        width: 100px;
        height: 92px;
        position: absolute;
        top: -15%;
        right: 0;

        @media #{$tab} {
          top: -5%;
          width: 60px;
          height: 52px;
        }

        @media #{$sp} {
          top: -5%;
          width: 40px;
          height: 32px;
        }
      }
    }
  }

  ul {
    padding: 0 140px;
    height: 100%;
    margin: 0 auto;

    @media #{$tab_s} {
      max-width: 100%;
      padding: 0;
    }

    li {
      font-family: $font_zenmaru;
      @include fz(34);
      color: #4d4d4d;
      font-weight: bold;
      line-height: 1.4;
      text-align: left;
      position: relative;
      margin-bottom: 40px;
      @include txt_in;

      @media #{$tab} {
        @include fz(27);
      }

      @media #{$sp} {
        @include fz(20);
        margin-bottom: 1em;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }

      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: $clr_org;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: .3em;

        @media #{$sp} {
          width: 13px;
          height: 13px;
          margin-top: -5px;
        }
      }
    }
  }

  .survey_sec04 {
    .pages_item_ttl {
      @media #{$sp} {
        width: 90%;
        margin: 0 auto 2em;
      }
    }
  }

  .survey_sec05 {
    ul {
      padding: 0 170px;
      position: relative;

      @media #{$tab_s} {
        padding: 0;
      }

      &:after {
        content: "";
        display: inline-block;
        background: url("../images/survey/sec05_img.png") right / contain no-repeat;
        width: 200px;
        height: 325px;
        position: absolute;
        top: 0;
        right: 20%;

        @media #{$tab_s} {
          right: 0;
        }

        @media #{$sp} {
          position: relative;
          top: 1em;
          width: 150px;
          height: 225px;
        }
      }
    }

    li {
      @include fz(27);
      margin-bottom: 15px;

      @media #{$sp} {
        @include fz(20);
      }
    }
  }

  .survey_sec06 {
    .pages_item_ttl {
      @media #{$sp} {
        width: 70%;
        margin: 0 auto 2em;
      }
    }

    .sec_fx_js {
      padding: 0 50px;

      @media #{$tab_s} {
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 0;
      }

      p {
        @media #{$tab} {
          width: 50%;
          margin-bottom: 2em;

          &:nth-last-of-type(1),
          &:nth-last-of-type(2) {
            margin-bottom: 0;
          }
        }

        @media #{$sp} {
          width: 45%;
          margin-bottom: 2em;

          &:nth-last-of-type(1) {
            width: 35%;
            margin: 0 auto 0 3em;
          }

          &:nth-last-of-type(2) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .sec_fx_js {
    display: flex;
    justify-content: space-between;

    @media #{$sp} {
      flex-wrap: wrap;
    }

    &:nth-of-type(1) {
      margin-bottom: 2em;
    }

    .pages_contents {
      width: 48.5%;

      @media #{$sp} {
        width: 100%;
        margin-bottom: 2em;
      }

      .pages_item {
        height: 100%;
        padding: 60px 30px 0 30px;

        @media #{$tab_s} {
          padding: 3em 1em 0 1em;
        }
      }
    }
  }

  .survey_sec07 {
    .pages_item {
      &_ttl {
        @media #{$sp} {
          width: 70%;
          margin: 0 auto 2em;
        }
      }

      &_col {

        dt,
        dd {
          margin-bottom: 25px;
        }

        dt {
          width: 25%;

          @media #{$sp} {
            width: 20%;
          }
        }

        dd {
          width: 75%;
          position: relative;

          &:after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            right: 10%;
          }

          &:nth-of-type(1) {
            &:after {
              background: url("../images/survey/sec07_icon.png") center /contain no-repeat;
              width: 103px;
              height: 51px;

              @media #{$tab_s} {
                width: 75px;
                height: 35px;
                right: 15%;
              }
            }
          }

          &:nth-of-type(2) {
            &:after {
              background: url("../images/survey/sec07_icon02.png") center /contain no-repeat;
              width: 59px;
              height: 58px;
              right: 15%;

              @media #{$tab_s} {
                width: 39px;
                height: 38px;
                right: 20%;
              }
            }
          }

          &:nth-of-type(3) {
            &:after {
              background: url("../images/survey/sec07_icon03.png") center /contain no-repeat;
              width: 60px;
              height: 58px;
              right: 15%;

              @media #{$tab_s} {
                width: 40px;
                height: 38px;
                right: 20%;
              }
            }
          }
        }

        .dt_s,
        .dd_s {
          margin-bottom: 15px;
        }

        .dd_ss {
          @include fz(22);

          @media #{$tab_s} {
            @include fz(16);
          }
        }
      }

      .bubble_item {
        width: 100%;

        .bubble {
          width: 100%;
          height: 50px;
          margin-bottom: 20px;

          @media #{$tab_s} {
            @include fz(15);
          }

          &:before,
          &:after {
            bottom: auto;
            right: auto;
            transform: rotate(180deg);
          }

          &:before {
            top: -24px;
            left: 8px;
          }

          &:after {
            top: -17px;
            left: 49px;
          }
        }
      }
    }
  }

  .survey_sec08 {
    .pages_item {
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        background: url("../images/survey/sec08_img.png") center/contain no-repeat;
        width: 207px;
        height: 155px;

        @media #{$sp} {
          width: 180px;
          height: 95px;
        }
      }

      &_ttl {
        @media #{$sp} {
          width: 60%;
          margin: 0 auto 2em;
        }
      }

      &_col {

        dt,
        dd {
          margin-bottom: 25px;
        }

        dt {
          width: 67%;
          color: $clr_gry;
          display: flex;
          align-items: center;

          &:after {
            content: "";
            flex-grow: 1;
            background: radial-gradient(circle farthest-side, $clr_gry, $clr_gry 30%, transparent 30%, transparent);
            background-size: 15px 15px;
            height: 15px;
            margin: 0 .2em;

            @media #{$sp} {
              background-size: 10px 10px;
              height: 10px;
              margin: 0 .5em;
            }
          }
        }

        dd {
          width: 15%;
          color: $clr_org;
          text-align: right;

          @media #{$sp} {
            width: 10%;
          }
        }
      }
    }
  }

  .survey_sec09,
  .survey_sec10 {
    @media #{$sp} {
      margin-bottom: 2em;
    }

    .pages_item_ttl {
      @media #{$sp} {
        width: 75%;
        margin: 0 auto;
      }
    }
  }
}